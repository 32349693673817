import { observable, decorate, action } from 'mobx';
import { sendPost } from 'fetchApi';

class ContactFormStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  error = undefined;

  async send(body, { isBusiness } = {}) {
    this.status = 'pending';
    this.error = undefined;
    try {
      await sendPost(isBusiness ? 'contact/businesses' : 'contact', body);
      this.status = 'done';
    } catch (e) {
      this.error = e?.message;
      this.status = 'error';
    }
  }

  reset() {
    this.status = undefined;
    this.error = undefined;
  }
}

export default decorate(ContactFormStore, {
  status: observable,
  error: observable,
  send: action.bound,
  reset: action.bound,
});
