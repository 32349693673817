/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PureComponent} from 'react';
import Header from 'components/Header';
import Icon from 'components/Icon';
import {observer, inject} from 'mobx-react';
import cn from 'classnames';
import {isMobile, isDesktop} from 'react-device-detect';

const SelectList = ({list, onSelect, value}) => (

  <section className={isMobile ? "layout__sect layout__sect--main" : "layout__sect layout__sect--main layout_sect-web"}>
    <div className="container">
      <ul className="layout__sect-select">
        {list.map((v) => (
          <li key={v.id} className="layout__sect-item">
            <div className="layout__sect-contains">
              <p className="layout__sect-text">
                <button
                  type="button"
                  className={cn('layout__sect-link', {'is--selected': v.id === value.id})}
                  onClick={() => onSelect(v)}
                >
                  {v.id === value.id ? <strong>{v.name}</strong> : v.name}
                </button>
              </p>
            </div>
          </li>
        ))}

        {/* <li className="layout__sect-item">
          <div className="layout__sect-contains">
            <p className="layout__sect-text">
              <a href="#" className="layout__sect-link is--selected"><strong>London</strong></a>
            </p>
          </div>
        </li> */}
      </ul>
    </div>
  </section>

);

class SelectLocationModal extends PureComponent {
  state = {
    visibleSelectForm: null,
    selectedCountry: null,
  };

  componentDidMount() {
    const {LocationStore} = this.props;
    const {
      hasGeoAccess, getCurrentPosition,
    } = LocationStore;
    if (hasGeoAccess) getCurrentPosition();
  }

  handleSelectCounty = (county) => {
    this.setState({visibleSelectForm: 'city', selectedCountry: county});
  }

  handleSelectCity = (city) => {
    const {AppStore} = this.props;
    AppStore.city = city;
    this.setState({visibleSelectForm: null, selectedCountry: null});
  }

  handleClickCounty = () => {
    this.setState({visibleSelectForm: 'country'});
  }

  handleClickCity = () => {
    this.setState({visibleSelectForm: 'city'});
  }

  handleBack = () => {
    const {visibleSelectForm, selectedCountry} = this.state;
    if (visibleSelectForm === 'city' && selectedCountry) {
      this.setState({visibleSelectForm: 'country'});
    } else {
      this.setState({visibleSelectForm: null, selectedCountry: null});
    }
  }

  handleClose = () => {
    const {AppStore} = this.props;
    AppStore.hideLocationModal();
  }

  render() {
    const {LocationStore, CountryStore, AppStore} = this.props;
    const {visibleSelectForm, selectedCountry} = this.state;
    const {country, city} = AppStore;
    const {
      hasGeoAccess, toggleGeoAccess, error, status,
    } = LocationStore;
    const {countries} = CountryStore;

    return (
      <>
        {isDesktop ?
          <>
            <Header onBack={visibleSelectForm ? this.handleBack : undefined}/>
            <main className="layout layout--default">
              <section className="layout__sect layout__sect--main layout_sect-web">
                {visibleSelectForm === 'country'
                && (
                  <SelectList
                    list={countries}
                    value={selectedCountry || country}
                    onSelect={this.handleSelectCounty}
                  />
                )}
                {visibleSelectForm === 'city'
                && (
                  <SelectList
                    list={selectedCountry?.cities || country?.cities}
                    value={city}
                    onSelect={this.handleSelectCity}
                  />
                )}
                {visibleSelectForm === null && (
                  <div className="container">
                    <div className="switch">
                      <p className="switch__text"><strong>Use geolocation</strong></p>

                      <div className="switch__wrap">
                        <input
                          type="checkbox"
                          className="visually-hidden"
                          id="switch"
                          checked={hasGeoAccess}
                          onChange={toggleGeoAccess}
                        />
                        <label className="switch__part" htmlFor="switch"/>
                      </div>
                    </div>

                    {status === 'pending' && (
                      <div style={{
                        paddingBottom: '15px',
                        textAlign: 'right',
                      }}
                      >
                        Finding...
                      </div>
                    )}
                    {status === 'error' && (
                      <div style={{
                        color: 'red',
                        paddingBottom: '15px',
                        textAlign: 'right',
                      }}
                      >
                        Error get location:
                        {' '}
                        {error}
                      </div>
                    )}
                    <ul className="layout__sect-links">
                      <li className="layout__sect-item">
                        <div className="layout__sect-contains">
                          <p className="layout__sect-text"><strong>Country</strong></p>
                          <p className="layout__sect-text">
                            <a href="#" onClick={this.handleClickCounty} className="layout__sect-link">
                              <span>{country?.name}</span>

                              <Icon name="arrow-icon" className="icon-arrow" width="7" height="12"/>
                            </a>
                          </p>
                        </div>
                      </li>

                      <li className="layout__sect-item">
                        <div className="layout__sect-contains">
                          <p className="layout__sect-text"><strong>City</strong></p>
                          <p className="layout__sect-text">
                            <a href="#" onClick={this.handleClickCity} className="layout__sect-link">
                              <span>{city?.name}</span>

                              <Icon name="arrow-icon" className="icon-arrow" width="7" height="12"/>
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </section>
            </main>
          </>
          :
          <div className="modal">
            <Header type="popup" onBack={visibleSelectForm ? this.handleBack : undefined} onClose={this.handleClose}/>
            <main className="layout layout--default">
              <section className="layout__sect layout__sect--main">
                {visibleSelectForm === 'country'
                && (
                  <SelectList
                    list={countries}
                    value={selectedCountry || country}
                    onSelect={this.handleSelectCounty}
                  />
                )}
                {visibleSelectForm === 'city'
                && (
                  <SelectList
                    list={selectedCountry?.cities || country?.cities}
                    value={city}
                    onSelect={this.handleSelectCity}
                  />
                )}
                {visibleSelectForm === null && (
                  <div className="container">
                    <div className="switch">
                      <p className="switch__text"><strong>Use geolocation</strong></p>

                      <div className="switch__wrap">
                        <input
                          type="checkbox"
                          className="visually-hidden"
                          id="switch"
                          checked={hasGeoAccess}
                          onChange={toggleGeoAccess}
                        />
                        <label className="switch__part" htmlFor="switch"/>
                      </div>
                    </div>

                    {status === 'pending' && (
                      <div style={{
                        paddingBottom: '15px',
                        textAlign: 'right',
                      }}
                      >
                        Finding...
                      </div>
                    )}
                    {status === 'error' && (
                      <div style={{
                        color: 'red',
                        paddingBottom: '15px',
                        textAlign: 'right',
                      }}
                      >
                        Error get location:
                        {' '}
                        {error}
                      </div>
                    )}
                    <ul className="layout__sect-links">
                      <li className="layout__sect-item">
                        <div className="layout__sect-contains">
                          <p className="layout__sect-text"><strong>Country</strong></p>
                          <p className="layout__sect-text">
                            <a href="#" onClick={this.handleClickCounty} className="layout__sect-link">
                              <span>{country?.name}</span>

                              <Icon name="arrow-icon" className="icon-arrow" width="7" height="12"/>
                            </a>
                          </p>
                        </div>
                      </li>

                      <li className="layout__sect-item">
                        <div className="layout__sect-contains">
                          <p className="layout__sect-text"><strong>City</strong></p>
                          <p className="layout__sect-text">
                            <a href="#" onClick={this.handleClickCity} className="layout__sect-link">
                              <span>{city?.name}</span>

                              <Icon name="arrow-icon" className="icon-arrow" width="7" height="12"/>
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </section>
            </main>
          </div>}
      </>
    );
  }
}

export default inject('AppStore', 'LocationStore', 'CountryStore')(observer(SelectLocationModal));
