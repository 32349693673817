import React from 'react';
import Icon from 'components/Icon';

const SocialSigIn = () => (
  <form className="form-socials" noValidate>
    <ul className="form-socials__list">
      <li className="form-socials__item">
        <a href="https://www.facebook.com/v8.0/dialog/oauth?client_id=326468708445857&scope=email&redirect_uri=https://bronfy.com/signin?auth=facebook" className="form-socials__link">
          <Icon name="fb-icon" width="10" height="21" />
        </a>
      </li>

      <li className="form-socials__item">
        <a href="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&response_type=code&client_id=447291514095-il0rtcdggj9o02385ua6fh9uqbjim5nb.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fbronfy.com%2Fsignin%3Fauth%3Dgoogle" className="form-socials__link">
          <Icon name="google-icon" width="30" height="19" />
        </a>
      </li>
    </ul>
  </form>
);

export default SocialSigIn;
