import React, { PureComponent } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

class SubCategories extends PureComponent {
  state = {};

  render() {
    const { category, value } = this.props;
    return (
      <div className="form-filter">
        <div className="form-filter__row">
          <ul className="form-filter__items">
            <li className="form-filter__item">
              <Link
                to={(location) => ({ ...location, pathname: `/category/${category.id}` })}
                className={cn(['form-filter__link-web', { 'is--active': !value }])}
                replace
              >
                All
              </Link>
            </li>
            {category.subcategories.map((subcategory) => (
              <li className="form-filter__item" key={subcategory.id}>
                <Link
                  to={(location) => ({ ...location, pathname: `/category/${category.id}/${subcategory.id}` })}
                  className={cn(['form-filter__link-web', { 'is--active': value && value.id === subcategory.id }])}
                  replace
                >
                  {subcategory.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default SubCategories;
