/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import Header from 'components/Header';
import Icon from 'components/Icon';
import {inject, observer} from 'mobx-react';
import {isDesktop, isMobile} from "react-device-detect";

const errorsText = {
  EmptyEmail: 'Email field is required',
  InvalidEmail: 'Invalid email address',
  EmptyPassword: 'Password is required',
};

class RestorePasswordPage extends React.Component {
  state = {
    isPasswordHidden: true,
  };

  emailRef = React.createRef();

  passwordRef = React.createRef();

  componentDidMount() {
    const {AppStore} = this.props;
    AppStore.menuOpenSectionName = 'profile';
  }

  componentWillUnmount() {
    const {RestorePasswordStore} = this.props;
    RestorePasswordStore.reset();
  }

  handleShowPassword = () => {
    this.setState((state) => ({isPasswordHidden: !state.isPasswordHidden}));
  }

  handleRestorePassword = (e) => {
    e.preventDefault();
    const {RestorePasswordStore, match} = this.props;
    const {code} = match.params;
    if (!code) {
      const email = this.emailRef.current.value;
      RestorePasswordStore.send(email);
    } else {
      const password = this.passwordRef.current.value;
      RestorePasswordStore.setNewPassword(code, password);
    }
  }

  render() {
    const {isPasswordHidden} = this.state;
    const {RestorePasswordStore, match} = this.props;
    const {code} = match.params;
    const error = RestorePasswordStore.error || RestorePasswordStore.setPasswordError;
    if (RestorePasswordStore.status === 'done') {
      return (
        <div className={isDesktop ? 'sign-in_web' : ''}>
          <div className={isDesktop ? 'wrap-web-restore' : ''}>
            <main className="layout">
              {isMobile? <Header type="logo"/>: ''}
              <div className={isMobile? '' : 'center-web'}>The link and instructions were sent to your e-mail</div>
              <br/>
              <Link style={{display: 'block'}} to="/signin">
                <button type="button" className="btn-submit">sign in</button>
              </Link>
            </main>
          </div>
        </div>
      );
    }
    if (RestorePasswordStore.setPasswordStatus === 'done') {
      return (
        <div className={isDesktop ? 'sign-in_web' : ''}>
          <div className={isDesktop ? 'wrap-web-restore' : ''}>
            <main className="layout">
              {isMobile? <Header type="logo"/>: ''}
              <div className={isMobile? '' : 'center-web'}>Success</div>
              <br/>
              <Link style={{display: 'block'}} to="/signin">
                <button type="button" className="btn-submit">sign in</button>
              </Link>
            </main>
          </div>
        </div>
      );
    }
    return (
      <div className={isDesktop ? 'sign-in_web' : ''}>
        <div className={isDesktop ? 'wrap-web-restore' : ''}>
          <main className="layout">
            {isMobile? <Header type="logo"/>: ''}

            <form action="/" className="form" noValidate>
              <ul className="form__items">
                {!code && (
                  <li className="form__item">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className={cn(['form__input',
                        {'is--error': error === 'EmptyEmail' || error === 'InvalidEmail' || error === 'UserNotFound'},
                      ])}
                      id="user-email"
                      ref={this.emailRef}
                    />
                    <label htmlFor="user-email" className="form__input-icon">
                      <Icon name="email-icon" width="22" height="17"/>
                    </label>
                  </li>
                )}
                {code
                && (
                  <li className="form__item">
                    <Icon
                      name="eye-icon"
                      className={cn([
                        'sprite-icon', 'sprite-icon--second', 'sprite-icon--eye', {'is--active': !isPasswordHidden},
                      ])}
                      width="18"
                      height="18"
                      onClick={this.handleShowPassword}
                    />
                    <input
                      name="password"
                      type={isPasswordHidden ? 'password' : 'text'}
                      placeholder="New password"
                      className={cn(['form__input',
                        {'is--error': error === 'EmptyPassword' || error === 'InvalidPassword'},
                      ])}
                      id="user-password"
                      ref={this.passwordRef}
                    />
                    <label htmlFor="user-password" className="form__input-icon">
                      <Icon name="lock-icon" width="22" height="17"/>
                    </label>
                  </li>
                )}

                <li className="form__item form__item-submit">
                  <button type="submit" className="btn-submit" onClick={this.handleRestorePassword}>submit</button>
                </li>
              </ul>
              {error && <div className="form__error"><p>{errorsText[error] || error}</p></div>}
            </form>
            <div className="layout__link">
              <p>
                Already a member?
                {' '}
                <Link to="/signin" className="layout__link-part">Sign In</Link>
              </p>
            </div>

          </main>
        </div>
      </div>
    );
  }
}

export default inject(
  ({RestorePasswordStore, AppStore}) => ({RestorePasswordStore, AppStore}),
)(observer(RestorePasswordPage));
