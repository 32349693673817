// eslint-disable-next-line max-classes-per-file
import React from 'react';
import './App.css';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
  withRouter,
} from 'react-router-dom';
import MainPage from 'containers/MainPage';
import FavoritePage from 'containers/FavoritePage';
import SignUpPage from 'containers/SignUpPage';
import SignInPage from 'containers/SignInPage';
import ProfilePage from 'containers/ProfilePage';
import ProfileEditPage from 'containers/ProfileEditPage';
import ChangePasswordPage from 'containers/ChangePasswordPage';
import PlacePage from 'containers/PlacePage';
import EmailConfirmPage from 'containers/EmailConfirmPage';
import RestorePasswordPage from 'containers/RestorePasswordPage';
import SelectLocationModal from 'containers/SelectLocationModal';
import PlaceMapPage from 'containers/PlaceMapPage';
import MapPage from 'containers/MapPage';
import ContactPage from 'containers/ContactPage';
import ForBusinessesPage from 'containers/ForBusinessesPage';
import HelpPage from 'containers/HelpPage';
import HistoryPage from 'containers/HistoryPage';
import LeftMenu from 'containers/LeftMenu';
import Footer from 'components/Footer';
import {isMobile, isDesktop} from 'react-device-detect';
import { inject, observer } from 'mobx-react';
import AboutPage from 'containers/AboutPage';
import Header from "./components/Header";

class _ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (
      location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

const ScrollToTop = withRouter(_ScrollToTop);
class App extends React.Component {
  componentDidMount() {
    const {
      SessionStore, CountryStore, CategoryStore, OptionStore, PlaceStore, PushNotificationStore,
    } = this.props;
    window.PushNotificationStore = PushNotificationStore;
    const handleAppClick = () => {
      PushNotificationStore.requestPermission();
      document.getElementById('root').removeEventListener('click', handleAppClick, false);
    };
    document.getElementById('root').addEventListener('click', handleAppClick);
    SessionStore.load();
    CountryStore.load();
    CategoryStore.load();
    OptionStore.load();
    PlaceStore.load();
  }

  render() {
    const {
      SessionStore, UserStore, AppStore, CategoryStore, CountryStore,
    } = this.props;

    if (AppStore.status === 'error' || CategoryStore.status === 'error' || CountryStore.status === 'error') {
      return `App start error: ${SessionStore.error || CategoryStore.error || CountryStore.error}`;
    }
    if (AppStore.status !== 'done' || CategoryStore.status !== 'done' || CountryStore.status !== 'done') {
      return 'Loading...';
    }
    return (
      <Router>
        {isMobile && AppStore.isShowLocationModal && <SelectLocationModal />}
        {isMobile? <LeftMenu /> : ''}
        <ScrollToTop />
        {isDesktop? <Header/>: ''}
        <Switch>

          <Route exact path="/map" component={MapPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/forbusinesses" component={ForBusinessesPage} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/about" component={AboutPage} />
          {isDesktop && <Route exact path="/modal" component={SelectLocationModal}/>}
          <Route path="/signin">
            {UserStore.user ? <Redirect to="/" /> : <SignInPage />}
          </Route>
          <Route path="/signup">
            {UserStore.user ? <Redirect to="/" /> : <SignUpPage />}
          </Route>
          <Route exact path="/profile">
            {!UserStore.user ? <Redirect to="/signin" /> : <ProfilePage />}
          </Route>
          <Route exact path="/profile/edit" component={ProfileEditPage} />
          <Route exact path="/profile/password" component={ChangePasswordPage} />
          <Route path="/favorite">
            {!UserStore.user ? <Redirect to="/signin" /> : <FavoritePage />}
          </Route>
          <Route path="/history">
            {!UserStore.user ? <Redirect to="/signin" /> : <HistoryPage />}
          </Route>

          <Route exact path="/place/:placeId" component={PlacePage} />
          <Route exact path="/place/:placeId/map" component={PlaceMapPage} />
          <Route exact path="/emailconfirm/:code" component={EmailConfirmPage} />
          <Route exact path="/restore" component={RestorePasswordPage} />
          <Route exact path="/restore/:code" component={RestorePasswordPage} />
          {/* <Route exact path="/favorite" component={FavoritePage} /> */}
          <Route
            exact
            path={[
              '/',
              '/category/:categoryId',
              '/category/:categoryId/:subcategoryId',
              '/new',
              '/new/:categoryId',
              '/new/:categoryId/:subcategoryId',
              '/promo',
              '/promo/:categoryId',
              '/promo/:categoryId/:subcategoryId',
              '/popular',
              '/popular/:categoryId',
              '/popular/:categoryId/:subcategoryId',
              '/search',
            ]}
            component={MainPage}
          />
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route>
            <div style={{display: "flex", color: "black", justifyContent: "center", alignItems: "center", paddingTop: "100px", fontSize: "24px", fontWeight: "700", flexDirection: "column"}}>
              <p>We are sorry,</p>
              <p style={{fontSize: "16px", fontWeight: "500", marginTop: "20px"}}>but the page you were looking can`t be found.</p>

              <p style={{fontSize: "32px", fontWeight: "700", marginTop: "50px"}}>404</p>
            </div>
            {/*404*/}
          </Route>

        </Switch>
        <Footer />

      </Router>
    );
  }
}

export default inject(
  'SessionStore', 'UserStore', 'AppStore', 'CountryStore', 'CategoryStore', 'OptionStore', 'PlaceStore',
  'PushNotificationStore',
)(observer(App));
