import { decorate, observable, action } from 'mobx';

class CategoryStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  error = undefined;

  categories = undefined;

  tags = {};

  categoriesArray = undefined;

  async load() {
    this.status = 'pending';
    this.error = undefined;
    try {
      const res = await fetch('/api/category');
      const { data } = await res.json();
      this.categoriesArray = data;
      this.categories = {};
      this.categoriesArray.forEach((cat) => {
        this.categories[cat.id] = cat;
        this.categories[cat.id].subcategories.forEach((subcategory) => {
          subcategory.tags.forEach((tag) => { this.tags[tag.id] = tag; });
        });
      });
      this.status = 'done';
    } catch (e) {
      console.error(e);
      this.error = e.message;
      this.status = 'error';
    }
  }
}

export default decorate(CategoryStore, {
  load: action.bound,
  status: observable,
  error: observable,
  categories: observable,
  categoriesArray: observable,
});
