import AppStore from './AppStore';
import SessionStore from './SessionStore';
import LocationStore from './LocationStore';
import UserStore from './UserStore';
import SignInFormStore from './SignInFormStore';
import SignUpFormStore from './SignUpFormStore';
import RestorePasswordStore from './RestorePasswordStore';
import CountryStore from './CountryStore';
import CategoryStore from './CategoryStore';
import OptionStore from './OptionStore';
import PlaceStore from './PlaceStore';
import PlacePriceStore from './PlacePriceStore';
import PlacePhotoStore from './PlacePhotoStore';
import ContactFormStore from './ContactFormStore';
import PushNotificationStore from './PushNotificationStore';

const stores = {};
stores.AppStore = new AppStore(stores);
stores.SessionStore = new SessionStore(stores);
stores.LocationStore = new LocationStore(stores);
stores.UserStore = new UserStore(stores);
stores.SignInFormStore = new SignInFormStore(stores);
stores.SignUpFormStore = new SignUpFormStore(stores);
stores.RestorePasswordStore = new RestorePasswordStore(stores);
stores.CountryStore = new CountryStore(stores);
stores.CategoryStore = new CategoryStore(stores);
stores.OptionStore = new OptionStore(stores);
stores.PlaceStore = new PlaceStore(stores);
stores.PlacePriceStore = new PlacePriceStore(stores);
stores.PlacePhotoStore = new PlacePhotoStore(stores);
stores.ContactFormStore = new ContactFormStore(stores);
stores.PushNotificationStore = new PushNotificationStore(stores);

export default stores;
