import React, {PureComponent} from 'react';
import {Redirect} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {sendGet} from 'fetchApi';
import Header from 'components/Header';
import {isDesktop, isMobile} from "react-device-detect";

class EmailConfirm extends PureComponent {
  state = {
    status: 'pending',
    error: undefined,
  }

  componentDidMount() {
    const {match, SessionStore} = this.props;
    if (match.params?.code) {
      sendGet(`emailconfirm/${match.params.code}`)
        .then(() => {
          SessionStore.load();
          this.setState({status: 'done'});
        })
        .catch((error) => this.setState({status: 'error', error: error.message}));
    }
  }

  render() {
    const {status, error} = this.state;
    if (status === 'pending') return (<div>Loading...</div>);
    if (status === 'done') return (<Redirect to="/profile"/>);
    return (
      <div>
        {isMobile ? <Header/> : ''}
        <div className={isDesktop ? 'confirm_email-web' : ''}>
          <div className={isDesktop ? 'wrap-web' : ''}>
            <main className="layout layout--main">
              Error:
              {' '}
              {error}
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  ({SessionStore}) => ({SessionStore}),
)(observer(EmailConfirm));
