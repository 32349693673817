/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import 'mobx-react-lite/batchingForReactDom';
import { Provider } from 'mobx-react';
import packageJson from '../package.json';
import './index.css';
import stores from './stores';
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Provider {...stores}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

window.appVersion = packageJson.version;
console.log('app version: ', window.appVersion);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// window.addEventListener('beforeinstallprompt', (event) => {
//   event.preventDefault();
//   setTimeout(() => {
//     event.prompt();
//   }, 1000 * 60 * 5);
// });
