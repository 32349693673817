/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import { inject, observer } from 'mobx-react';
import Header from 'components/Header';
import PlacesList from 'components/PlacesList';

class HistoryPage extends React.PureComponent {
  searchRef = React.createRef();

  componentDidMount() {
    const { AppStore } = this.props;
    AppStore.menuOpenSectionName = 'profile';
  }

  render() {
    const { PlaceStore, UserStore } = this.props;
    const { user } = UserStore;
    const { places, status } = PlaceStore;

    if (status === 'pending') return 'Loading...';
    if (status === 'error') return 'Loading error';
    const placesById = {};
    if (user?.history) places.forEach((place) => { placesById[place.id] = place; });
    const filteredPlaces = user?.history.map((id) => placesById[id]);
    return (
      <>
        <Header />
        <main className="layout layout--main">
          {user?.history && user?.history.length > 0
            ? (
              <PlacesList
                // isGoBack={true}
                // history={this.props.history}
                places={filteredPlaces}
                favorites={UserStore.user?.favorites}
                onAddFavorite={UserStore.addFavorite}
                onRemoveFavorite={UserStore.removeFavorite}
                useIndex
                title={"My History"}
                bigCards={true}
              />
            )
            : (<div style={{ textAlign: 'center', paddingTop: '20px', fontSize: "16px", fontWeight: "700" }}>No results found</div>)}
        </main>
      </>
    );
  }
}

export default inject('AppStore', 'PlaceStore', 'UserStore')(observer(HistoryPage));
