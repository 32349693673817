import { decorate, observable, action } from 'mobx';

class SessionStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  error = undefined;

  session = undefined;

  async load() {
    this.status = 'pending';
    this.error = undefined;
    try {
      const res = await fetch('/api/session');
      const { data } = await res.json();
      const { user, ...session } = data;
      this.session = session;
      if (user) this.stores.UserStore.user = user;
      this.stores.AppStore.status = 'done';
      this.status = 'done';
    } catch (e) {
      console.error(e);
      this.error = e.message;
      this.status = 'error';
      if (this.stores.AppStore.status === 'pending') {
        this.stores.AppStore.status = 'error';
      }
    }
  }
}

export default decorate(SessionStore, {
  load: action.bound,
  status: observable,
  error: observable,
  session: observable,
});
