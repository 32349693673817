/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import Header from 'components/Header';
import { observer, inject } from 'mobx-react';
import {isMobile} from 'react-device-detect';

class HelpPage extends PureComponent {
  render() {
    const { history } = this.props;

    return (
      <>
        {isMobile? <Header
          isShowFilterToggler={false}
          history={history}
          type={history.action === 'PUSH' ? 'second' : undefined}
          title="Back"
        /> : ""}

        <main className="layout layout--main">
          <section className="layout__sect layout__sect--main">
            <div className="container">
              <h1 className="layout__sect-title">Help</h1>
              <br />
              <p style={{textAlign: "center"}}>We decided to remove this part for now, in the future we will add user guide illustrations.</p>
            </div>
          </section>
        </main>

      </>
    );
  }
}

export default inject('AppStore')(observer(HelpPage));
