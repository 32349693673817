/* eslint-disable global-require */
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import Icon from 'components/Icon/Icon';
import {isMobile, isDesktop} from 'react-device-detect';
import Slider from "react-slick";

export const categoryIcon = {
  cafe: require('img/categories/cafe.svg'),
  fun: require('img/categories/fun.svg'),
  beauty: require('img/categories/beauty.svg'),
  sport: require('img/categories/sport.svg'),
  services: require('img/categories/services.svg'),
  travel: require('img/categories/travel.svg'),
  auto: require('img/categories/auto.svg'),
  kids: require('img/categories/kids.svg'),
};

class Categories extends PureComponent {
  state = {
    isShowAll: false,
  };

  handleClickToggleShowAll = () => {
    this.setState((state) => ({isShowAll: !state.isShowAll}));
  };

  render() {
    const {isShowAll} = this.state;
    let {categories} = this.props;
    if (isDesktop) {
      this.setState({isShowAll: true})
    }
    if (!isShowAll) {
      categories = categories.slice(0, 8);
    }

    if (isMobile) {
      return (
        <div className="container">
          <ul className="list">
            {categories.map((category) => (
              <li className="list__item" key={category.id}>
                <Link to={`category/${category.id}`} className="list__item-link">
                  <div className="list__item-block">
                    <Icon name={`category-${category.icon}-icon`}/>
                  </div>
                  <span className="list__item-text">{category.name}</span>
                </Link>
              </li>
            ))}
            {(isShowAll || categories.length < 8) && (
              <li className="list__item">
                <Link to="/new" className="list__item-link">
                  <div className="list__item-block">
                    <Icon name="category-new-icon"/>
                  </div>
                  <span className="list__item-text">New</span>
                </Link>
              </li>
            )}
          </ul>
          {(categories.length >= 8) && (
            <button className="btn" type="button" id="btn-all" onClick={this.handleClickToggleShowAll}>
              {isShowAll ? 'Hide' : 'See all'}
            </button>
          )}
        </div>
      );
    }
    return (
      <div className="places_cont">
        <div className="category_add_wrap">
          <div className="categories_wrap">
            <h1 className="categories_h1">
              Categories
            </h1>
            <div className='main__hr'/>
            <div className="slider-wrap-categories ">
              <Slider
                dots={false}
                arrows={true}
                infinite={false}
                speed={500}
                slidesToShow={categories.length > 7 ? 8 : categories.length}
                slidesToScroll={2}
                className="slider-categories"
                responsive={[
                  {
                    breakpoint: 1500,
                    settings: {
                      slidesToShow: (categories.length > 6 ? 7 : categories.length),
                      slidesToScroll: 2,
                      classname: "slider-categories",
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 1300,
                    settings: {
                      slidesToShow: (categories.length > 5 ? 6 : categories.length),
                      slidesToScroll: 2,
                      classname: "slider-categories",
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: (categories.length > 4 ? 5 : categories.length),
                      slidesToScroll: 2,
                      classname: "slider-categories",
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: (categories.length > 3 ? 4 : categories.length),
                      slidesToScroll: 2,
                      classname: "slider-categories",
                      arrows: true
                    }
                  }
                ]}
              >
                {categories.map((category) => (
                  <div className="layout__categories" key={category.id}>
                    <Link to={`category/${category.id}`} className="list__item-link_web">
                      <div className="list__item-block_web">
                        <Icon name={`category-${category.icon}-icon`}/>
                      </div>
                      <span className="list__item-text">{category.name}</span>
                    </Link>
                  </div>
                ))}
                <div className="layout__categories">
                  <Link to="/new" className="list__item-link_web">
                    <div className="list__item-block_web">
                      <Icon name="category-new-icon"/>
                    </div>
                    <span className="list__item-text">New</span>
                  </Link>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Categories;
