import React from 'react';
import './TermsModal.css';

const TermsModal = ({ onClose }) => (
  <div className="termsModal">
    <div className="termsModal__wrapper">
      <b>Terms and Conditions</b>
      <div className="termsModal__content">
        These “Term of Services” (hereinafter Terms) shall apply to all relations between Bronfy.com and the User related to the use of the Bronfy.com Platform.
        <br />
        <br />
        By signing up in the Bronfy.com Platform, the User agrees to the application and content of these Terms and is informed about the processing of his/her personal data disclosed during registration and use of the Bronfy.com Platform on the conditions set out in these Terms.
        <br />
        <br />
        • Bronfy.com Platform enables the User to reserve / book / purchase / order services / goods provided by Partners at the terms agreed between themselves;
        <br />
        <br />
        • Bronfy.com is not the manufacturer or seller or service provider and is not liable in any way for the performance of the Partners;
        <br />
        <br />
        • By operating the Bronfy.com Platform, Bronfy.com acts as the agent in relation to mediation between the User and Partner.
      </div>
      <button type="submit" className="btn-submit" onClick={onClose}>Close</button>
    </div>
  </div>
);

export default TermsModal;
