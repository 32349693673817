import { decorate, observable, action } from 'mobx';

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function checkGeoAccessInStorage() {
  const { hasGeoAccess } = window.localStorage;
  if (!hasGeoAccess) return false;
  return typeof hasGeoAccess === 'string' ? JSON.parse(hasGeoAccess) : hasGeoAccess;
}

class LocationStore {
  constructor(stores) {
    this.stores = stores;
  }

  hasGeoAccess = checkGeoAccessInStorage();

  error = undefined;

  status = undefined;

  position = undefined;

  gmapsStatus = undefined;

  gmapsPositionInfo = undefined;

  showAlert(text, time = 2000) {
    this.alertText = text;
    clearTimeout(this.alertTimeoutId);
    this.alertTimeout = setTimeout(() => {
      this.alertText = undefined;
    }, time);
  }

  handlePosition = (position) => {
    this.position = position;
    this.error = undefined;
    this.status = 'done';
    const { latitude, longitude } = position.coords;
    const nearCity = this.stores.CountryStore.countries
      .map(({ id, cities }) => cities
        .map((city) => ({
          countryId: id,
          distance: getDistanceFromLatLonInKm(latitude, longitude, city.coords[0], city.coords[1]),
          ...city,
        })))
      .flat()
      .sort((a, b) => a.distance - b.distance)[0];
    this.stores.AppStore.city = nearCity;
  }

  handlePositionError = (err) => {
    console.error(err);
    this.error = err.message;
    this.status = 'error';
    if (this.hasGeoAccess) {
      this.hasGeoAccess = false;
      window.localStorage.setItem('hasGeoAccess', 'false');
    }
  }

  getCurrentPosition() {
    this.status = 'pending';
    if (!this.watchPosition) {
      this.watchId = navigator.geolocation.watchPosition(this.handlePosition, this.handlePositionError);
    } else {
      navigator.geolocation.getCurrentPosition(this.handlePosition, this.handlePositionError);
    }
  }

  toggleGeoAccess() {
    this.error = undefined;
    this.status = undefined;
    if (!this.hasGeoAccess) {
      this.hasGeoAccess = true;
      window.localStorage.setItem('hasGeoAccess', 'true');
      this.getCurrentPosition();
    } else {
      this.hasGeoAccess = false;
      window.localStorage.setItem('hasGeoAccess', 'false');
      navigator.geolocation.clearWatch(this.watchID);
      this.watchId = undefined;
    }
  }
}

export default decorate(LocationStore, {
  status: observable,
  error: observable,
  hasGeoAccess: observable,
  position: observable,
  toggleGeoAccess: action.bound,
  getCurrentPosition: action.bound,
});
