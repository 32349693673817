import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import Icon from 'components/Icon';
import {isMobile} from 'react-device-detect';

class PlacesList extends PureComponent {
  state = {};

  handleClickFavorite = (id) => (e) => {
    e.preventDefault();
    const {onAddFavorite} = this.props;
    onAddFavorite(id);
  }

  handleClickRemoveFavorite = (id) => (e) => {
    e.preventDefault();
    const {onRemoveFavorite} = this.props;
    onRemoveFavorite(id);
  }

  render() {
    const {
      places, isPromo, categories, favorites = [], useIndex, title, bigCards = false, history, isGoBack
    } = this.props;
    if (isMobile) {
      return (
        <section className="layout__sect">
          <div className="container">
            {places.map((place, index) => (
              <Link to={`/place/${place.id}`} className="card card--full" key={useIndex ? index : place.id}>
                <div className="card__img">
                  {isPromo && place.promoPoster
                    ? <img src={`${place.promoPoster}/700`} alt="" className="object-fit is--cover"/>
                    : place.poster && <img src={`${place.poster}/700`} alt="" className="object-fit is--cover"/>}
                  {place.isNew && <div className="card__label-new">New</div>}
                </div>

                <div className="card__info">
                  <h2 className="card__info-title">{place.name}</h2>

                  <div className="card__info-wrap">
                    <div className="card__info-icon card__info-icon--grey">
                      {categories && place.categories && place.categories.map((catId, i) => categories[catId]
                        && [
                          <Icon key={catId} name={`category-${categories[catId].icon}-icon`}/>,
                          (i % 2 ? <br key={`br_${catId}`}/> : null),
                        ])}
                    </div>
                    <p className="card__info-text card__info-tags">{isPromo ? place.promoText : place.description}</p>
                  </div>
                  {favorites.includes(place.id)
                    ? (
                      <button type="button" className="btn-favorite" onClick={this.handleClickRemoveFavorite(place.id)}>
                        <Icon className="btn-favorite__icon" name="favorite-full-icon" width="21" height="19"/>
                      </button>
                    )
                    : (
                      <button type="button" className="btn-favorite" onClick={this.handleClickFavorite(place.id)}>
                        <Icon className="btn-favorite__icon" name="favorite-icon" width="21" height="19"/>
                      </button>
                    )}
                </div>
              </Link>
            ))}
          </div>
        </section>
      );
    }
    return (
      <div className="places_cont">
        <div className={bigCards? "places_add_wrap-big" : "places_add_wrap"}>
        {title && title !== "Search" ?
          <div className="places_header_wrap">
            {isGoBack && (
              <div className="wrap_main_back" onClick={() => history.goBack()}>
                <div className="map_back_div">
                  <span className="map_back_arrow"/>
                </div>
                <div className="map_back_div2">
                  <span className="map_back_span">Go Back</span>
                </div>
              </div>
            )}
            <h1 className="categories_h1">
              {title}
            </h1>
            <div className='main__hr'/>
          </div> :
          ""}
        <div className={bigCards? "places_wrap-big" : "places_wrap"}>
          {places.map((place, index) => (

            <Link to={`/place/${place.id}`} className={bigCards? "card-placesList-web-cat" : "card-placesList-web"} key={useIndex ? index : place.id}>
              <div className="card__img_placesList">
                {isPromo && place.promoPoster
                  ? <img src={`${place.promoPoster}/700`} alt="" className="object-fit is--cover"/>
                  : place.poster && <img src={`${place.poster}/700`} alt="" className="object-fit is--cover"/>}
                {place.isNew && <div className="card__label-new">New</div>}
              </div>
              <li className={bigCards? "favorite_pos_placesList-big header__item" : "favorite_pos_placesList header__item"}>
                {favorites.includes(place.id)
                  ? (
                    <button type="button" className="btn-favorite" onClick={this.handleClickRemoveFavorite(place.id)}>
                      <Icon className="btn-favorite__icon" name="favorite-full-icon" width="21" height="19"/>
                    </button>
                  )
                  : (
                    <button type="button" className="btn-favorite" onClick={this.handleClickFavorite(place.id)}>
                      <Icon className="btn-favorite__icon" name="favorite-icon" width="21" height="19"/>
                    </button>
                  )}
              </li>

              <div className={bigCards? "card__info-web-big" : "card__info-web"}>
                <h2 className={bigCards? "card__info-title-places-big" : "card__info-title-places-web"}>{place.name}</h2>

                <div className={bigCards? "card__info-wrap_placesList-big" : "card__info-wrap_placesList"}>
                  {!bigCards && (
                    <div className="card__info-icon card__info-icon--grey">
                    {categories && place.categories && place.categories.map((catId, i) => categories[catId]
                      && [
                        <Icon key={catId} name={`category-${categories[catId].icon}-icon`}/>,
                        (i % 2 ? <br key={`br_${catId}`}/> : null),
                      ])}
                  </div>
                  )}

                  <p className="card__info-text card__info-tags">{isPromo ? place.promoText : place.description}</p>
                </div>
              </div>

            </Link>
          ))}
        </div>
      </div>
          </div>
    )

  }
}

export default PlacesList;
