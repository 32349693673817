import { decorate, observable, action } from 'mobx';

class PlacePhotoStore {
  constructor(stores) {
    this.stores = stores;
  }

  items = {};

  async load(placeId) {
    if (!this.items[placeId]) this.items[placeId] = {};
    this.items[placeId].status = 'pending';
    this.items[placeId].error = undefined;
    try {
      const res = await fetch(`/api/place/${placeId}/photo`);
      const { data } = await res.json();
      this.items[placeId].photos = data;
      this.items[placeId].status = 'done';
    } catch (e) {
      console.error(e);
      this.items[placeId].error = e.message;
      this.items[placeId].status = 'error';
    }
  }
}

export default decorate(PlacePhotoStore, {
  load: action.bound,
  items: observable,
});
