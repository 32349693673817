/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';
import Header from 'components/Header/Header';
import Icon from 'components/Icon/Icon';
import { when } from 'mobx';

const errorsText = {
  EmptyEmail: 'Email field is required',
  InvalidEmail: 'Invalid email address or password',
  EmailAlreadyRegistered: 'This email is already registered',
  EmptyPassword: 'Password is required',
  InvalidPassword: 'Invalid email address or password',
};

class ProfileEditPage extends PureComponent {
  state = {}

  nameRef = React.createRef();

  emailRef = React.createRef();

  phoneRef = React.createRef();

  componentDidMount() {
    const { history, UserStore } = this.props;
    when(
      () => UserStore.profileSaveStatus === 'done',
      () => {
        history.push('/profile');
        UserStore.profileSaveStatus = undefined;
      },
    );
  }

  handleSave = (e) => {
    e.preventDefault();
    const { UserStore } = this.props;
    const name = this.nameRef.current.value;
    const email = this.emailRef.current.value;
    const phone = this.phoneRef.current.value;
    UserStore.saveProfileInfo({ name, email, phone });
  }

  render() {
    const { history, UserStore } = this.props;
    const error = UserStore.profileSaveError;

    if (!UserStore.user) history.replace('/signin');
    // console.log('UserStore.profileSaveStatus', UserStore.profileSaveStatus);

    return (
      <>
        <Header
          type="second"
          title="Back"
          isShowFilterToggler={false}
          onFilterToggle={this.handleToggleShowFilter}
          history={history}
        />
        <main className="layout layout--main">
          <section className="layout__sect layout__sect--main">
            <div className="container">

              <h1 className="layout__sect-title">Edit profile</h1>
              <br />
              <form action="/" className="form" noValidate>
                <ul className="form__items">
                  <li className="form__item">
                    <input
                      name="name"
                      type="text"
                      placeholder="Name"
                      className={cn(['form__input',
                        { 'is--error': error === 'EmptyName' },
                      ])}
                      id="user-name"
                      ref={this.nameRef}
                      defaultValue={UserStore.user.name}
                    />
                    <label className="form__input-icon" htmlFor="user-name">
                      <Icon name="user-icon" width="20" height="20" />
                    </label>
                  </li>

                  <li className="form__item">
                    <input
                      name="phone"
                      type="text"
                      placeholder="Phone"
                      className={cn(['form__input'])}
                      id="user-phone"
                      ref={this.phoneRef}
                      defaultValue={UserStore.user.phone}
                    />
                    <label className="form__input-icon" htmlFor="user-phone">
                      <Icon name="user-icon" width="20" height="20" />
                    </label>
                  </li>

                  <li className="form__item">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className={cn(['form__input',
                        { 'is--error': ['EmptyEmail', 'InvalidEmail', 'EmailAlreadyRegistered'].includes(error) },
                      ])}
                      id="user-email"
                      ref={this.emailRef}
                      defaultValue={UserStore.user.email}
                    />
                    <label className="form__input-icon" htmlFor="user-email">
                      <Icon name="email-icon" width="22" height="17" />
                    </label>
                  </li>

                  <li className="form__item form__item-submit">
                    <button type="submit" className="btn-submit" onClick={this.handleSave}>Save</button>
                  </li>
                </ul>
                {error && (
                  <div className="form__error">
                    <p>{errorsText[error] || error}</p>
                  </div>
                )}
              </form>
            </div>
          </section>
        </main>
      </>
    );
  }
}
export default inject('UserStore')(observer(ProfileEditPage));
