/* eslint-disable no-param-reassign */
import React from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import sprite from '../img/sprite.svg';
import Icon from "./Icon";
import {BrowserView, MobileView} from 'react-device-detect';

const Footer = ({UserStore, AppStore}) => (
  <>
    <BrowserView class={"margin-top-auto"}>
      <div className="footer-wrap">
        <div className="container header_cont">
          <div className="header-wrap">
            <footer className="footer-web" style={{paddingBottom: 40, paddingTop: 10}}>
              <div className="socials-wrap-web socials-wrap--second">
                Copyright 2021
              </div>
              <div>
                &nbsp;
              </div>
              <div>
                &nbsp;
              </div>

              <div className="socials-wrap-web socials-wrap--second">
                <ul className="socials-web socials--second">
                  <li className="socials__item">
                    <a
                      href="https://www.facebook.com/Bronfycom-113502717113821"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="socials__link"
                    >
                      <Icon className="socials__link-icon" width="10" height="21" name="fb-icon"/>
                    </a>
                  </li>

                  <li className="socials__item">
                    <a
                      href="https://www.instagram.com/bronfy_com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="socials__link"
                    >
                      <Icon className="socials__link-icon" width="18" height="18" name="inst-icon"/>
                    </a>
                  </li>

                  <li className="socials__item">
                    <a
                      href="https://www.linkedin.com/company/bronfy-com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="socials__link"
                    >
                      <Icon className="socials__link-icon" width="22" height="22" name="lnk-icon"/>
                    </a>
                  </li>
                </ul>
              </div>

            </footer>
          </div>
        </div>
      </div>
    </BrowserView>
    <MobileView>
      <footer className="footer" style={{paddingBottom: 53}}>
        <nav className="nav" id="navigation">
          <ul className="nav__list">
            <li className="nav__list-item">
              <Link
                to="/"
                className={cn(['nav__list-link', {'is--active': AppStore?.menuOpenSectionName === 'main'}])}
                onClick={() => {
                  AppStore.menuOpenSectionName = 'main';
                }} //  TODO refact, create handler
              >
                <svg className="nav__list-icon" width="19" height="20">
                  <use xlinkHref={`${sprite}#house-icon`}/>
                </svg>
              </Link>
            </li>

            <li className="nav__list-item">
              <Link
                to="/search"
                className={cn(['nav__list-link', {'is--active': AppStore?.menuOpenSectionName === 'search'}])}
                onClick={() => {
                  AppStore.menuOpenSectionName = 'search';
                }}
              >
                <svg className="nav__list-icon" width="20" height="19">
                  <use xlinkHref={`${sprite}#search-icon`}/>
                </svg>
              </Link>
            </li>

            <li className="nav__list-item">
              <Link
                to="/favorite"
                className={cn(['nav__list-link', {'is--active': AppStore?.menuOpenSectionName === 'favorite'}])}
                onClick={() => {
                  AppStore.menuOpenSectionName = 'favorite';
                }}
              >
                <svg className="nav__list-icon" width="21" height="19">
                  <use xlinkHref={`${sprite}#heart-icon`}/>
                </svg>
              </Link>
            </li>

            <li className="nav__list-item">
              <Link
                to="/map"
                className={cn(['nav__list-link', {'is--active': AppStore?.menuOpenSectionName === 'map'}])}
                onClick={() => {
                  AppStore.menuOpenSectionName = 'map';
                }}
              >
                <svg className="nav__list-icon" width="15" height="22">
                  <use xlinkHref={`${sprite}#man-icon`}/>
                </svg>
              </Link>
            </li>

            <li className="nav__list-item">
              <Link
                to={UserStore.user ? '/profile' : '/signin'}
                className={cn(['nav__list-link', {'is--active': AppStore?.menuOpenSectionName === 'profile'}])}
                onClick={() => {
                  AppStore.menuOpenSectionName = 'profile';
                }}
              >
                <svg className="nav__list-icon" width="17" height="19">
                  <use xlinkHref={`${sprite}#profile-icon`}/>
                </svg>
              </Link>
            </li>
          </ul>
        </nav>
      </footer>
    </MobileView>
  </>
);

export default inject(
  ({UserStore, AppStore}) => ({UserStore, AppStore}),
)(observer(Footer));
