/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {PureComponent} from 'react';
import {inject, observer} from 'mobx-react';
import cn from 'classnames';
import Header from 'components/Header/Header';
import Icon from 'components/Icon/Icon';
import {when} from 'mobx';
import {isDesktop, isMobile} from "react-device-detect";

const errorsText = {
  EmptyPassword: 'Current password is required',
  EmptyNewPassword: 'New password is required',
  InvalidPassword: 'Invalid password',
};

class ChangePasswordPage extends PureComponent {
  state = {
    isPasswordHidden: true,
  }

  currentPasswordRef = React.createRef();

  newPasswordRef = React.createRef();

  componentDidMount() {
    const {history, UserStore, isPopUp, closeModal} = this.props;
    when(
      () => (UserStore.passwordSaveStatus === 'done' && !isPopUp),
      () => {
        history.push('/profile');
        UserStore.passwordSaveStatus = undefined;
      },
    );
    when(
      () => (UserStore.passwordSaveStatus === 'done' && isPopUp),
      () => {
        closeModal();
        UserStore.passwordSaveStatus = undefined;
      },
    );
  }

  handleShowPassword = () => {
    this.setState((state) => ({isPasswordHidden: !state.isPasswordHidden}));
  }

  handleSave = (e) => {
    e.preventDefault();
    const {UserStore} = this.props;
    const currentPassword = UserStore.user.hasPassword ? this.currentPasswordRef.current.value : undefined;
    const newPassword = this.newPasswordRef.current.value;
    UserStore.saveNewPassword({currentPassword, newPassword});
  }

  render() {
    const {history, UserStore, closeModal, isPopUp = false} = this.props;
    const {isPasswordHidden} = this.state;
    const error = UserStore.passwordSaveError;
    if (!UserStore.user) history.replace('/signin');
    if (isDesktop && isPopUp) {
      return (
        <div className='sign-in_web-popUp'>
          <div className='wrap-web-restore-popUp'>
            <main className='layout layout--main-web-change'>
              <section className="layout__sect layout__sect--main">
                <div className="container">

                  <h1 className="layout__sect-title">Change password</h1>
                  <hr className='restore__hr'/>

                  <div className="close_modal">
                    <button type="button" onClick={closeModal} className="close" id="close-menu">
                      <span className="close__part" />
                      <span className="close__part" />
                    </button>
                  </div>

                  <form action="/" className="form" noValidate>
                    <ul className="form__items">

                      {UserStore.user.hasPassword && (
                        <li className="form__item">
                          <Icon
                            name="eye-icon"
                            className={cn([
                              'sprite-icon', 'sprite-icon--second', 'sprite-icon--eye', {'is--active': !isPasswordHidden},
                            ])}
                            width="18"
                            height="18"
                            onClick={this.handleShowPassword}
                          />
                          <input
                            name="currentPassword"
                            type={isPasswordHidden ? 'password' : 'text'}
                            placeholder="Current password"
                            className={cn(['form__input',
                              {'is--error': error === 'EmptyPassword' || error === 'InvalidPassword'},
                            ])}
                            id="user-current-password"
                            ref={this.currentPasswordRef}
                          />
                          <label htmlFor="user-current-password" className="form__input-icon">
                            <Icon name="lock-icon" width="22" height="17"/>
                          </label>
                        </li>
                      )}

                      <li className="form__item">
                        <Icon
                          name="eye-icon"
                          className={cn([
                            'sprite-icon', 'sprite-icon--second', 'sprite-icon--eye', {'is--active': !isPasswordHidden},
                          ])}
                          width="18"
                          height="18"
                          onClick={this.handleShowPassword}
                        />
                        <input
                          name="newPassword"
                          type={isPasswordHidden ? 'password' : 'text'}
                          placeholder="New password"
                          className={cn(['form__input',
                            {'is--error': error === 'EmptyNewPassword'},
                          ])}
                          id="user-new-password"
                          ref={this.newPasswordRef}
                        />
                        <label htmlFor="user-new-password" className="form__input-icon">
                          <Icon name="lock-icon" width="22" height="17"/>
                        </label>
                      </li>

                      <li className="form__item form__item-submit">
                        <button type="submit" className="btn-submit" onClick={this.handleSave}>Save</button>
                      </li>
                    </ul>
                    {error && (
                      <div className="form__error">
                        <p>{errorsText[error] || error}</p>
                      </div>
                    )}
                  </form>
                </div>
              </section>
            </main>
          </div>
        </div>
      )
    }


    return (
      <>
        {isMobile ? <Header
          type="second"
          title="Back"
          isShowFilterToggler={false}
          onFilterToggle={this.handleToggleShowFilter}
          history={history}
        /> : ''}
        <div className={isDesktop ? 'sign-in_web' : ''}>
          <div className={isDesktop ? 'wrap-web-restore' : ''}>
            <main className={isDesktop ? 'layout layout--main-web-change' : 'layout layout--main'}>
              <section className="layout__sect layout__sect--main">
                <div className="container">

                  <h1 className="layout__sect-title">Change password</h1>
                  {isDesktop? <hr className='restore__hr'/>: <br/>}

                  <form action="/" className="form" noValidate>
                    <ul className="form__items">

                      {UserStore.user.hasPassword && (
                        <li className="form__item">
                          <Icon
                            name="eye-icon"
                            className={cn([
                              'sprite-icon', 'sprite-icon--second', 'sprite-icon--eye', {'is--active': !isPasswordHidden},
                            ])}
                            width="18"
                            height="18"
                            onClick={this.handleShowPassword}
                          />
                          <input
                            name="currentPassword"
                            type={isPasswordHidden ? 'password' : 'text'}
                            placeholder="Current password"
                            className={cn(['form__input',
                              {'is--error': error === 'EmptyPassword' || error === 'InvalidPassword'},
                            ])}
                            id="user-current-password"
                            ref={this.currentPasswordRef}
                          />
                          <label htmlFor="user-current-password" className="form__input-icon">
                            <Icon name="lock-icon" width="22" height="17"/>
                          </label>
                        </li>
                      )}

                      <li className="form__item">
                        <Icon
                          name="eye-icon"
                          className={cn([
                            'sprite-icon', 'sprite-icon--second', 'sprite-icon--eye', {'is--active': !isPasswordHidden},
                          ])}
                          width="18"
                          height="18"
                          onClick={this.handleShowPassword}
                        />
                        <input
                          name="newPassword"
                          type={isPasswordHidden ? 'password' : 'text'}
                          placeholder="New password"
                          className={cn(['form__input',
                            {'is--error': error === 'EmptyNewPassword'},
                          ])}
                          id="user-new-password"
                          ref={this.newPasswordRef}
                        />
                        <label htmlFor="user-new-password" className="form__input-icon">
                          <Icon name="lock-icon" width="22" height="17"/>
                        </label>
                      </li>

                      <li className="form__item form__item-submit">
                        <button type="submit" className="btn-submit" onClick={this.handleSave}>Save</button>
                      </li>
                    </ul>
                    {error && (
                      <div className="form__error">
                        <p>{errorsText[error] || error}</p>
                      </div>
                    )}
                  </form>
                </div>
              </section>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default inject('UserStore')(observer(ChangePasswordPage));
