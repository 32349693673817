import { observable, decorate, action } from 'mobx';
import { sendPost, sendGet } from 'fetchApi';

class SignInFormStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  error = undefined;

  async send(email, password) {
    this.status = 'pending';
    this.error = undefined;
    try {
      await sendPost('signin', { email, password });
      await this.stores.SessionStore.load();
      this.status = 'done';
      this.stores.AppStore.showAlert('You are sign in');
    } catch (e) {
      this.error = e?.message;
      this.status = 'error';
    }
  }

  // TODO refact it
  async sendGoogleAuthCode(code) {
    try {
      await sendGet('google/oauth2callback', { code });
      await this.stores.SessionStore.load();
      this.status = 'done';
      this.stores.AppStore.showAlert('You are sign in');
    } catch (e) {
      console.error({ e });
      this.error = e?.message;
      this.status = 'error';
    }
  }

  // TODO refact it
  async sendFacebookAuthCode(code) {
    try {
      await sendGet('facebook/oauth2callback', { code });
      await this.stores.SessionStore.load();
      this.status = 'done';
      this.stores.AppStore.showAlert('You are sign in');
    } catch (e) {
      console.error({ e });
      this.error = e?.message;
      this.status = 'error';
    }
  }

  reset() {
    this.status = undefined;
    this.error = undefined;
  }
}

export default decorate(SignInFormStore, {
  status: observable,
  error: observable,
  send: action.bound,
  sendGoogleAuthCode: action.bound,
  sendFacebookAuthCode: action.bound,
  reset: action.bound,
});
