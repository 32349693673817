/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line max-classes-per-file
import React, {PureComponent} from 'react';
import {inject, observer} from 'mobx-react';
import Slider from 'react-slick';
// import Header from 'components/Header';
import {BrowserView, MobileView, isMobile} from 'react-device-detect';

import Icon from 'components/Icon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class _Prices extends PureComponent {
  componentDidMount() {
    const {PlacePriceStore, place} = this.props;
    PlacePriceStore.load(place.id);
  }

  render() {
    const {PlacePriceStore, place} = this.props;
    const {prices} = PlacePriceStore.items[place.id] || {};
    if (!prices || prices.length === 0) return null;
    if (isMobile) {
      return (
        <div className="blocks">
          <div className="blocks__body">
            <ul className="cards-list">
              {prices.map((price) => (
                <li key={price.id} className="cards-list__item">
                <span href="#" className="card card--fourth">
                  <div className="card__img">
                    {price.poster && <img src={`${price.poster}/500`} alt="" className="object-fit is--cover"/>}
                  </div>

                  <div className="card__info">
                    <h2 className="card__info-title">{price.name}</h2>
                    <div className="card__info-wrap">
                      <p className="card__info-text"><span>{price.cost}</span></p>
                    </div>
                  </div>
                </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
    return (
      <div className='slider-container'>
        <h1 className="prices-h1">Propositions</h1>
        <div className="slider-wrap-prop slider-max-w">
          <Slider
            dots={false}
            arrows={true}
            infinite={false}
            speed={500}
            slidesToShow={prices.length > 5 ? 6 : prices.length}
            slidesToScroll={prices.length > 5 ? 3 : 1}
            className="slider-prop"
            responsive={[
              {
                breakpoint: 1250,
                settings: {
                  slidesToShow: (prices.length > 4 ? 5 : prices.length),
                  slidesToScroll: (prices.length > 4 ? 2 : 1),
                  classname: "slider-prop",
                  arrows: true
                }
              },
              {
                breakpoint: 1050,
                settings: {
                  slidesToShow: (prices.length > 3 ? 4 : prices.length),
                  slidesToScroll: (prices.length > 3 ? 2 : 1),
                  classname: "slider-prop",
                  arrows: true
                }
              },
              {
                breakpoint: 850,
                settings: {
                  slidesToShow: (prices.length > 2 ? 3 : prices.length),
                  slidesToScroll: (prices.length > 2 ? 2 : 1),
                  classname: "slider-prop",
                  arrows: true
                }
              }
            ]}
          >
            {prices.map((price) => (
              <div key={price.id} className="layout__detail-img-props">
                {price.poster && <img src={`${price.poster}/500`} alt="" className="object-fit is--cover props-img"/>}
                <h2 className="card__info-title-web">{price.name}</h2>
                <div className="card__info-wrap-web">
                  <p className="card__info-text"><span>{price.cost}</span></p>
                </div>
              </div>
            ))}

          </Slider>
        </div>
      </div>
    )

  }
}

const Prices = inject('PlacePriceStore')(observer(_Prices));

class PlacePage extends PureComponent {
  componentDidMount() {
    const {PlacePhotoStore, match} = this.props;
    const placeId = parseInt(match.params.placeId, 10);
    if (!PlacePhotoStore.items[placeId]?.photos) PlacePhotoStore.load(placeId);
  }

  handleAddFavorite = (e) => {
    e.preventDefault();
    const {UserStore, history, match} = this.props;
    const placeId = parseInt(match.params.placeId, 10);
    if (!UserStore.user) {
      history.push('/signin');
    } else {
      UserStore.addFavorite(placeId);
    }
  }

  handleRemoveFavorite = () => {
    const {UserStore, history, match} = this.props;
    const placeId = parseInt(match.params.placeId, 10);
    if (!UserStore.user) {
      history.push('/signin');
    } else {
      UserStore.removeFavorite(placeId);
    }
  }

  handleClickMap = () => {
    const {history, match} = this.props;
    history.push(`/place/${match.params.placeId}/map`);
  }

  handleCall = () => {
    const {UserStore, match} = this.props;
    const placeId = parseInt(match.params.placeId, 10);
    UserStore.addHistory(placeId);
  }

  render() {
    const {
      PlaceStore, PlacePhotoStore, OptionStore, UserStore, history, match,
    } = this.props;
    const favorites = UserStore.user?.favorites || [];
    const {status, places} = PlaceStore;
    const {options} = OptionStore;
    if (status === 'pending') return 'Loading...';
    if (status === 'error') return 'Loading error';
    const placeId = parseInt(match.params.placeId, 10);
    const place = places.find((v) => v.id === placeId);
    if (!place) return (
      <div style={{textAlign: "center", marginTop: "100px", fontSize: "18px"}}>
        Place not Found
      </div>
    );
    const photos = PlacePhotoStore.items[placeId]?.photos;
    return (
      <>
        <MobileView>
          <header className="header header--detail">
            <div className="container">
              <ul className="header__items">
                <li className="header__item header__item-link">
                  <button type="button" onClick={history.goBack} className="btn-link">
                    <Icon name="back-icon2" className="btn-link__icon" width="18" height="10"/>
                  </button>
                </li>

                <li className="header__item">
                  {favorites.includes(place.id)
                    ? (
                      <button type="button" className="btn-favorite" onClick={this.handleRemoveFavorite}>
                        <Icon className="btn-favorite__icon" name="favorite-full-icon" width="21" height="19"/>
                      </button>
                    )
                    : (
                      <button type="button" className="btn-favorite" onClick={this.handleAddFavorite}>
                        <Icon className="btn-favorite__icon" name="favorite-icon" width="21" height="19"/>
                      </button>
                    )}
                </li>
              </ul>
            </div>
          </header>
          <main className="layout layout--default">
            <section className="layout__detail">
              <div className="layout__detail-head-mobile">
                <Slider
                  dots
                  infinite
                  arrows={false}
                  speed={500}
                  slidesToShow={1}
                  slidesToScroll={1}
                  dotsClass="placePosterDot"
                >
                  {place.poster && (
                    <div className="layout__detail-img">
                      <img src={`${place.poster}/700`} alt="" className="object-fit is--cover"/>
                    </div>
                  )}
                  {photos && photos.map(({file}) => (
                    <div key={file} className="layout__detail-img">
                      <img src={`${file}/700`} alt="" className="object-fit is--cover"/>
                    </div>
                  ))}
                  {!place.poster && !photos?.length && <div className="layout__detail-img"/>}
                </Slider>
              </div>

              <div className="layout__detail-body">
                <h1 className="layout__detail-title">{place.name}</h1>
                {!!place.isPromo && <p className="layout__detail-subtitle">{place.promoText}</p>}
                <p className="layout__detail-subtitle">{place.description}</p>

                {place.phone && (
                  <div className="layout__detail-btn">
                    <a href={`tel:${place.phone}`} className="btn btn--third" onClick={this.handleCall}>
                      <Icon name="bell-icon" className="btn__icon" width="29" height="29"/>
                      <span className="btn__text">call</span>
                    </a>
                  </div>
                )}

                <Prices place={place}/>

                {place.menu && (
                  <div className="layout__detail-divider">
                    <a
                      href={place.menu}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn"
                      download
                    >
                      DOWNLOAD PRICES
                    </a>
                  </div>
                )}

                {place.menuLink && (
                  <div className="layout__detail-divider">
                    <a
                      href={place.menuLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn"
                    >
                      PRICES LINK
                    </a>
                  </div>
                )}

                <div className="container">
                  <div className="block-inf">

                    {(place.ratingLink || place.rating) && (
                      <ul className="block-inf__rows">
                        <li className="block-inf__row">
                          <Icon name="star-icon" className="block-inf__icon" width="19" height="24"/>
                          <p className="block-inf__text"><strong>Rating:</strong></p>
                        </li>

                        <li className="block-inf__row block-inf__row__rating">
                          <p className="block-inf__text">
                            {place.rating}
                          </p>
                        </li>

                        {place.ratingLink && (
                          <li className="block-inf__row">
                            <a
                              href={place.ratingLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="block-inf__link"
                            >
                              <Icon name="link-icon" className="block-inf__icon" width="24" height="24"/>
                              <span className="block-inf__label">Link</span>
                            </a>
                          </li>
                        )}
                      </ul>
                    )}

                    {(place.address || place.coords) && (
                      <ul className="block-inf__rows">
                        <li className="block-inf__row">
                          <Icon name="location-icon" className="block-inf__icon" width="13" height="16"/>
                          <p className="block-inf__text"><strong>Address:</strong></p>
                        </li>
                        <li className="block-inf__row">
                          <p className="block-inf__text">
                            {place.address}
                          </p>
                        </li>

                        {place.coords && (
                          <li className="block-inf__row">
                            <button
                              type="button"
                              onClick={this.handleClickMap}
                              rel="noopener noreferrer"
                              className="block-inf__link"
                            >
                              <Icon name="map-icon" className="block-inf__icon" width="20" height="18"/>
                              <span className="block-inf__label">Map</span>
                            </button>
                          </li>
                        )}

                      </ul>
                    )}

                    {place.workTime && (
                      <ul className="block-inf__rows">
                        <li className="block-inf__row">
                          <Icon name="clock-icon" className="block-inf__icon" width="14" height="14"/>
                          <p className="block-inf__text"><strong>Work:</strong></p>
                        </li>

                        <li className="block-inf__row">
                        <pre className="block-inf__text">
                          {place.workTime}
                        </pre>
                        </li>
                      </ul>
                    )}

                    {place.note && (
                      <ul className="block-inf__rows">
                        <li className="block-inf__row">
                          <Icon name="info-icon" className="block-inf__icon" width="14" height="14"/>
                          <p className="block-inf__text"><strong>Info:</strong></p>
                        </li>

                        <li className="block-inf__row">
                        <pre className="block-inf__text">
                          {place.note}
                        </pre>
                        </li>
                      </ul>
                    )}

                    {(place.email) && (
                      <ul className="block-inf__rows">
                        <li className="block-inf__row">
                          <Icon name="email-icon" className="block-inf__icon" width="14" height="14"/>
                          <p className="block-inf__text"><strong>Email:</strong></p>
                        </li>

                        <li className="block-inf__row">
                          <p className="block-inf__text block-inf__text__links">
                            {place.email.split('\n').map((email, i) => (
                              <span key={email}>
                              {i > 0 && <br/>}
                                <a href={`mailto:${email}`} className="block-inf__label">{email}</a>
                            </span>
                            ))}
                          </p>
                        </li>
                      </ul>
                    )}

                  </div>
                </div>

                {(place.site || place.facebook || place.instagram || place.whatsapp || place.vk || place.linkedin) && (
                  <div className="container">
                    <div className="socials-wrap">
                      <ul className="socials">

                        {place.site && (
                          <li className="socials__item">
                            <a href={place.site} target="_blank" rel="noopener noreferrer" className="socials__link">
                              <Icon name="menu-icon-lang" className="socials__link-icon" width="18" height="18"/>
                            </a>
                          </li>
                        )}

                        {place.facebook && (
                          <li className="socials__item">
                            <a href={place.facebook} target="_blank" rel="noopener noreferrer"
                               className="socials__link">
                              <Icon name="fb-icon" className="socials__link-icon" width="10" height="21"/>
                            </a>
                          </li>
                        )}

                        {place.instagram && (
                          <li className="socials__item">
                            <a href={place.instagram} target="_blank" rel="noopener noreferrer"
                               className="socials__link">
                              <Icon name="inst-icon" className="socials__link-icon" width="18" height="18"/>
                            </a>
                          </li>
                        )}

                        {place.whatsapp && (
                          <li className="socials__item">
                            <a
                              href={`https://api.whatsapp.com/send?phone=${place.whatsapp}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="socials__link"
                            >
                              <Icon name="whatsapp-icon" className="socials__link-icon" width="21" height="21"/>
                            </a>
                          </li>
                        )}

                        {place.vk && (
                          <li className="socials__item">
                            <a href={place.vk} target="_blank" rel="noopener noreferrer" className="socials__link">
                              <Icon name="vk-icon" className="socials__link-icon" width="24" height="24"/>
                            </a>
                          </li>
                        )}

                        {place.linkedin && (
                          <li className="socials__item">
                            <a href={place.linkedin} target="_blank" rel="noopener noreferrer"
                               className="socials__link">
                              <Icon name="lnk-icon" className="socials__link-icon" width="22" height="22"/>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
                {(place.options && place.options.length > 0) && (
                  <div className="container">
                    <div className="block-inf block-inf--second">

                      <div className="block-inf__blocks">
                        {place.options
                          .sort((a, b) => options[a.id].index - options[b.id].index)
                          .map((option) => (
                            <div key={option.id}>
                              <div>
                                <img
                                  src={options[option.id].icon}
                                  style={{marginRight: '10px', maxHeight: '24px', maxWidth: '24px'}}
                                  alt={options[option.id].name}
                                />
                                {(options[option.id].hasText && option.text && (
                                  <p className="block-inf__text">
                                    {`${options[option.id].name}:`}
                                    <br/>
                                    <small>{option.text}</small>
                                  </p>
                                ))
                                || (
                                  <p className="block-inf__text">
                                    {options[option.id].name}
                                  </p>
                                )}

                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </main>
        </MobileView>
        <BrowserView>

          <main className="layout layout--default">
            <div className="place_cont">
              <section className="layout__detail-web">
                <div className="positionBack" onClick={() => history.goBack()}>
                  <div className="positionBackArrow">
                    <span/>
                  </div>
                  <div className="positionBackWord">
                    <span>Go Back</span>
                  </div>
                </div>
                <li className="header__item favorite_pos">
                  {favorites.includes(place.id)
                    ? (
                      <button type="button" className="btn-favorite" onClick={this.handleRemoveFavorite}>
                        <Icon className="btn-favorite__icon" name="favorite-full-icon" width="21" height="19"/>
                      </button>
                    )
                    : (
                      <button type="button" className="btn-favorite" onClick={this.handleAddFavorite}>
                        <Icon className="btn-favorite__icon" name="favorite-icon" width="21" height="19"/>
                      </button>
                    )}
                </li>
                <div className="detail-web">
                  <div className="slider-wrap">
                    <Slider
                      dots
                      arrows={false}
                      infinite
                      speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                      dotsClass="placePosterDot-web"
                    >
                      {place.poster && (
                        <div className="layout__detail-img">
                          <img src={`${place.poster}/700`} alt="" className="object-fit is--cover"/>
                        </div>
                      )}
                      {photos && photos.map(({file}) => (
                        <div key={file} className="layout__detail-img">
                          <img src={`${file}/700`} alt="" className="object-fit is--cover"/>
                        </div>
                      ))}
                      {!place.poster && !photos?.length && <div className="layout__detail-img"/>}
                    </Slider>
                  </div>
                  <div className="detail-web-info">
                    <div className="detail-head-web">
                      <h1 className="layout__detail-title-web">{place.name}</h1>
                      {!!place.isPromo && <p className="detail-web-promo">{place.promoText}</p>}
                    </div>
                    <p className="layout__detail-subtitle-web">{place.description}</p>
                    {(place.site || place.facebook || place.instagram || place.whatsapp || place.vk || place.linkedin) && (
                      <div className="container">
                        <div className="socials-wrap-web">
                          <ul className="socials-web-detail">

                            {place.site && (
                              <li className="socials__item-detail">
                                <a href={place.site} target="_blank" rel="noopener noreferrer"
                                   className="socials__link-web">
                                  <Icon name="menu-icon-lang" className="socials__link-icon" width="18" height="18"/>
                                </a>
                              </li>
                            )}

                            {place.facebook && (
                              <li className="socials__item-detail">
                                <a href={place.facebook} target="_blank" rel="noopener noreferrer"
                                   className="socials__link-web">
                                  <Icon name="fb-icon" className="socials__link-icon" width="10" height="21"/>
                                </a>
                              </li>
                            )}

                            {place.instagram && (
                              <li className="socials__item-detail">
                                <a href={place.instagram} target="_blank" rel="noopener noreferrer"
                                   className="socials__link-web">
                                  <Icon name="inst-icon" className="socials__link-icon" width="18" height="18"/>
                                </a>
                              </li>
                            )}

                            {place.whatsapp && (
                              <li className="socials__item-detail">
                                <a
                                  href={`https://api.whatsapp.com/send?phone=${place.whatsapp}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="socials__link-web"
                                >
                                  <Icon name="whatsapp-icon" className="socials__link-icon" width="21" height="21"/>
                                </a>
                              </li>
                            )}

                            {place.vk && (
                              <li className="socials__item-detail">
                                <a href={place.vk} target="_blank" rel="noopener noreferrer"
                                   className="socials__link-web">
                                  <Icon name="vk-icon" className="socials__link-icon" width="24" height="24"/>
                                </a>
                              </li>
                            )}

                            {place.linkedin && (
                              <li className="socials__item-detail">
                                <a href={place.linkedin} target="_blank" rel="noopener noreferrer"
                                   className="socials__link-web">
                                  <Icon name="lnk-icon" className="socials__link-icon" width="22" height="22"/>
                                </a>
                              </li>
                            )}
                            {(place.ratingLink || place.rating) && (
                              <li className="socials__item-detail-rating">
                                <div className="wrap-detail-block-rating">
                                  <ul className="block-inf__rows">
                                    <li className="block-inf__row-web">
                                      <Icon name="star-icon" className="block-inf__icon" width="19" height="24"/>
                                      <p className="block-inf__text"><strong>Rating:</strong></p>
                                    </li>

                                    <li className="block-inf__row-web block-inf__row__rating">
                                      <p className="block-inf__text-rating">
                                        {place.rating}
                                      </p>
                                    </li>

                                    {place.ratingLink && (
                                      <li className="block-inf__row-web block-inf__row__rating">
                                        <a
                                          href={place.ratingLink}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="block-inf__link"
                                        >
                                          <Icon name="link-icon" className="block-inf__icon" width="24" height="24"/>
                                          <span className="block-inf__label">Link</span>
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    )}


                    <div className="detail-info-wrap-flex">
                      {(place.address || place.coords) && (
                        <div className="wrap-detail-block">
                          <ul className="block-inf__rows">
                            <li className="block-inf__row-web">
                              <Icon name="location-icon" className="block-inf__icon" width="13" height="16"/>
                              <p className="block-inf__text"><strong>Address:</strong></p>
                            </li>
                            <li className="block-inf__row">
                              <p className="block-inf__text">
                                {place.address}
                                <button
                                  type="button"
                                  onClick={this.handleClickMap}
                                  rel="noopener noreferrer"
                                  className="block-inf__link"
                                >
                                  <Icon name="map-icon" className="block-inf__icon" width="20" height="18"/>
                                  <span className="block-inf__label">Map</span>
                                </button>
                              </p>
                            </li>
                          </ul>
                        </div>
                      )}

                      {place.workTime && (
                        <div className="wrap-detail-block">
                          <ul className="block-inf__rows">
                            <li className="block-inf__row-web">
                              <Icon name="clock-icon" className="block-inf__icon" width="14" height="14"/>
                              <p className="block-inf__text"><strong>Work:</strong></p>
                            </li>

                            <li className="block-inf__row-web">
                        <pre className="block-inf__text">
                          {place.workTime}
                        </pre>
                            </li>
                          </ul>
                        </div>
                      )}

                      {place.note && (
                        <div className="wrap-detail-block">
                          <ul className="block-inf__rows">
                            <li className="block-inf__row-web">
                              <Icon name="info-icon" className="block-inf__icon" width="14" height="14"/>
                              <p className="block-inf__text"><strong>Info:</strong></p>
                            </li>

                            <li className="block-inf__row-web">
                        <pre className="block-inf__text">
                          {place.note}
                        </pre>
                            </li>
                          </ul>
                        </div>
                      )}

                      {(place.email) && (
                        <div className="wrap-detail-block">
                          <ul className="block-inf__rows">
                            <li className="block-inf__row-web">
                              <Icon name="email-icon" className="block-inf__icon" width="14" height="14"/>
                              <p className="block-inf__text"><strong>Email:</strong></p>
                            </li>

                            <li className="block-inf__row-web">
                              <p className="block-inf__text block-inf__text__links">
                                {place.email.split('\n').map((email, i) => (
                                  <span key={email}>
                              {i > 0 && <br/>}
                                    <a href={`mailto:${email}`} className="block-inf__label">{email}</a>
                            </span>
                                ))}
                              </p>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="detail-btn-div">
                      {place.phone && (
                        <div className="layout__detail-btn-web">
                          <a href={`tel:${place.phone}`} className="btn-web btn--third call_btn" onClick={this.handleCall}>
                            <Icon name="bell-icon" className="btn__icon" width="29" height="29"/>
                            <span className="btn__text call_text">Phone {place.phone}</span>
                          </a>
                        </div>
                      )}

                      {place.menu && (
                        <div className="layout__detail-divider-web">
                          <a
                            href={place.menu}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn-web btn-web-another"
                            download
                          >
                            DOWNLOAD PRICES
                          </a>
                        </div>
                      )}

                      {place.menuLink && (
                        <div className="layout__detail-divider-web">
                          <a
                            href={place.menuLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn-web btn-web-another"
                          >
                            PRICING
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="wrap-prices">
                  <Prices place={place}/>
                </div>
                <div className="wrap-options">
                  {(place.options && place.options.length > 0) && (
                    <h1 className="addInfo-h1">Additional Information</h1>)}
                  {(place.options && place.options.length > 0) && (
                    <div className="container pad-0">
                      <div className="block-inf block-inf--second">

                        <div className="block-inf__blocks-web">
                          {place.options
                            .sort((a, b) => options[a.id].index - options[b.id].index)
                            .map((option) => (
                              <div key={option.id}>
                                <div>
                                  <img
                                    src={options[option.id].icon}
                                    style={{
                                      marginLeft: '20px',
                                      marginRight: '20px',
                                      maxHeight: '24px',
                                      maxWidth: '24px'
                                    }}
                                    alt={options[option.id].name}
                                  />
                                  {(options[option.id].hasText && option.text && (
                                    <p className="block-inf__text">
                                      {`${options[option.id].name}:`}
                                      <br/>
                                      <small>{option.text}</small>
                                    </p>
                                  ))
                                  || (
                                    <p className="block-inf__text">
                                      {options[option.id].name}
                                    </p>
                                  )}

                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

              </section>
            </div>
          </main>
        </BrowserView>
      </>
    );
  }
}

export default inject(
  'AppStore', 'CategoryStore', 'CountryStore', 'OptionStore', 'PlaceStore', 'PlacePhotoStore', 'UserStore',
)(observer(PlacePage));
