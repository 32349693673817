import { decorate, observable, action } from 'mobx';
import { sendPost } from 'fetchApi';

class UserStore {
  constructor(stores) {
    this.stores = stores;
  }

  user = undefined;

  profileSaveStatus = undefined;

  profileSaveError = undefined;

  passwordSaveStatus = undefined;

  passwordSaveError = undefined;

  async signOut() {
    try {
      await sendPost('signout');
      this.user = undefined;
      this.stores.AppStore.showAlert('You are signed out');
    } catch (e) {
      console.error(e);
    }
  }

  async addHistory(id) {
    try {
      if (this.user) {
        if (!this.user.history) this.user.history = [];
        this.user.history = [id, ...this.user.history];
        await sendPost('history', { id });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async addFavorite(id) {
    try {
      if (this.user) {
        if (!this.user.favorites) this.user.favorites = [];
        if (!this.user.favorites.includes(id)) {
          this.user.favorites = [...this.user.favorites, id];
          await sendPost('favorite', { id });
        }
      }
    } catch (e) {
      if (this.user && this.user.favorites) {
        this.user.favorites = this.user.favorites.filter((v) => v !== id);
      }
    }
  }

  async removeFavorite(id) {
    try {
      if (this.user && this.user.favorites) {
        if (this.user.favorites && this.user.favorites.includes(id)) {
          this.user.favorites = this.user.favorites.filter((v) => v !== id);
          await sendPost('favorite/remove', { id });
        }
      }
    } catch (e) {
      if (this.user && this.user.favorites) {
        this.user.favorites.push(id);
      }
    }
  }

  async saveProfileInfo({ name, email, phone }) {
    try {
      const { data } = await sendPost('profile', { name, email, phone });
      this.user = {
        ...this.user, name: data.name, email: data.email, phone: data.phone, isNeedConfirmed: data.isNeedConfirmed,
      };
      this.stores.AppStore.showAlert('Saved');
    } catch (e) {
      console.error(e);
      this.stores.AppStore.showAlert(`Save error: ${e.message}`);
    }
  }

  async saveNewPassword({ currentPassword, newPassword }) {
    try {
      this.passwordSaveError = undefined;
      this.passwordSaveStatus = 'pending';
      await sendPost('profile/password', { currentPassword, newPassword });
      this.user.hasPassword = true;
      this.passwordSaveStatus = 'done';
      this.stores.AppStore.showAlert('Password save');
    } catch (e) {
      console.error(e);
      this.passwordSaveError = e.message;
      this.passwordSaveStatus = 'error';
    }
  }
}

export default decorate(UserStore, {
  user: observable,
  passwordSaveStatus: observable,
  passwordSaveError: observable,
  signOut: action.bound,
  addFavorite: action.bound,
  removeFavorite: action.bound,
  saveProfileInfo: action.bound,
  addHistory: action.bound,
});
