/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Icon from 'components/Icon';
import SocialSigIn from 'components/SocialSigIn';
import TermsModal from './TermsModal';
import {isDesktop, isMobile} from "react-device-detect";

const errorsText = {
  EmptyEmail: 'Email field is required',
  InvalidEmail: 'Invalid email address or password',
  EmailAlreadyRegistered: 'This email is already registered',
  EmptyPassword: 'Password is required',
  InvalidPassword: 'Invalid email address or password',
};

class SignUpPage extends React.PureComponent {
  state = {
    isPasswordHidden: true,
  };

  nameRef = React.createRef();

  emailRef = React.createRef();

  passwordRef = React.createRef();

  componentDidMount() {
    const { AppStore } = this.props;
    AppStore.menuOpenSectionName = 'profile';
  }

  componentWillUnmount() {
    const { SignUpFormStore } = this.props;
    SignUpFormStore.reset();
  }

  handleShowPassword = () => {
    this.setState((state) => ({ isPasswordHidden: !state.isPasswordHidden }));
  }

  handleSignUp = (e) => {
    e.preventDefault();
    const { SignUpFormStore } = this.props;
    const name = this.nameRef.current.value;
    const email = this.emailRef.current.value;
    const password = this.passwordRef.current.value;
    SignUpFormStore.send({ name, email, password });
  }

  handleShowTermsModal = (e) => {
    e.preventDefault();
    this.setState({ isShowTermsModal: true });
  }

  handleHideTermsModal = () => {
    this.setState({ isShowTermsModal: false });
  }

  render() {
    const { isPasswordHidden, isShowTermsModal } = this.state;
    const { SignUpFormStore } = this.props;
    const { error } = SignUpFormStore;
    return (
      <main className="layout">
        {isMobile? <Header type="logo" />: ''}
        {isMobile? isShowTermsModal && <TermsModal onClose={this.handleHideTermsModal} />: ''}
        <div className={isDesktop? 'sign-in_web' : ''}>
          <div className={isDesktop? 'wrap-web' : ''}>
            {isDesktop? <h1 className='login__h1'>Sign Up</h1> : ''}
            {isDesktop? <hr className='signUp__hr'/>: ''}
        <form action="/" className={isMobile? "form" : "form-web"} noValidate>
          <ul className={isDesktop? "form__items " : "form__items"}>
            <li className="form__item">
              <input
                name="name"
                type="text"
                placeholder="Name"
                className={cn(['form__input',
                  { 'is--error': error === 'EmptyName' },
                ])}
                id="user-name"
                ref={this.nameRef}
              />
              <label className="form__input-icon">
                <Icon name="user-icon" width="20" height="20" />
              </label>
            </li>

            <li className="form__item">
              <input
                name="email"
                type="email"
                placeholder="Email Address"
                className={cn(['form__input',
                  { 'is--error': ['EmptyEmail', 'InvalidEmail', 'EmailAlreadyRegistered'].includes(error) },
                ])}
                id="user-email"
                ref={this.emailRef}
              />
              <label htmlFor="user-email" className="form__input-icon">
                <Icon name="email-icon" width="22" height="17" />
              </label>
            </li>

            <li className="form__item">
              <Icon
                name="eye-icon"
                className={`sprite-icon sprite-icon--second sprite-icon--eye ${!isPasswordHidden ? 'is--active' : ''}`}
                width="18"
                height="18"
                onClick={this.handleShowPassword}
              />
              <input
                name="password"
                type={isPasswordHidden ? 'password' : 'text'}
                placeholder="Password"
                className={cn(['form__input',
                  { 'is--error': error === 'EmptyPassword' },
                ])}
                id="user-password"
                ref={this.passwordRef}
              />
              <label htmlFor="user-password" className="form__input-icon">
                <Icon name="lock-icon" width="22" height="17" />
              </label>
            </li>

            <li className="form__item layout__link">
              By pressing sing up you agree to
              {' '}
              <a className="layout__link-part" href="#" onClick={this.handleShowTermsModal}>Terms and Conditions</a>
            </li>

            <li className="form__item form__item-submit">
              <button type="submit" className="btn-submit" onClick={this.handleSignUp}>Sign up</button>
            </li>
          </ul>
          {SignUpFormStore.error && (
            <div className="form__error">
              <p>{errorsText[SignUpFormStore.error] || SignUpFormStore.error}</p>
            </div>
          )}
        </form>

        <div className="layout__link">
          <p>
            Already a member?
            {' '}
            <Link to="/signin" className="layout__link-part">Sign In</Link>
          </p>
        </div>

        <span className="layout__divider">Or</span>

        <SocialSigIn />
          </div>
        </div>
      </main>
    );
  }
}

export default inject(
  ({ SignUpFormStore, AppStore }) => ({ SignUpFormStore, AppStore }),
)(observer(SignUpPage));
