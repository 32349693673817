import { decorate, observable, action } from 'mobx';

class CountryStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  error = undefined;

  countries = undefined;

  async load() {
    this.status = 'pending';
    this.error = undefined;
    try {
      const res = await fetch('/api/country');
      const { data } = await res.json();
      this.countries = data;
      this.status = 'done';
      if (this.stores.LocationStore.hasGeoAccess) {
        this.stores.LocationStore.getCurrentPosition();
      }
    } catch (e) {
      console.error(e);
      this.error = e.message;
      this.status = 'error';
    }
  }
}

export default decorate(CountryStore, {
  load: action.bound,
  status: observable,
  error: observable,
  countries: observable,
});
