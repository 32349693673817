export const sendPost = async function sendPost(url, body) {
  const res = await fetch(`/api/${url}`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const result = await res.json();
  if (res.status !== 200) {
    const error = new Error(result.error?.message);
    error.status = res.status;
    error.response = result;
    throw error;
  }
  return result;
};

export const sendGet = async function sendGet(url, query) {
  const queryString = query ? `?${new URLSearchParams(query).toString()}` : '';
  const res = await fetch(`/api/${url}${queryString}`, { credentials: 'include' });
  const result = await res.json();
  if (res.status !== 200) {
    const error = new Error(result.error?.message);
    error.status = res.status;
    error.response = result;
    throw error;
  }
  return result;
};

export default { sendPost, sendGet };
