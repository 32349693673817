import { decorate, observable, action } from 'mobx';

class OptionStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  error = undefined;

  options = undefined;

  optionsArray = undefined;

  async load() {
    this.status = 'pending';
    this.error = undefined;
    try {
      const res = await fetch('/api/option');
      const { data } = await res.json();
      this.optionsArray = data;
      this.options = {};
      this.optionsArray.forEach((cat) => {
        this.options[cat.id] = cat;
      });
      this.status = 'done';
    } catch (e) {
      console.error(e);
      this.error = e.message;
      this.status = 'error';
    }
  }
}

export default decorate(OptionStore, {
  load: action.bound,
  status: observable,
  error: observable,
  options: observable,
  optionsArray: observable,
});
