import React, { Component } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon/Icon';
import { inject, observer } from 'mobx-react';
import logo from '../img/logo.svg';

class LeftMenu extends Component {
  state = {}

  handleSignOut = () => {
    const { AppStore, UserStore } = this.props;
    UserStore.signOut();
    AppStore.closeLeftMenu();
  }

  render() {
    const { AppStore, UserStore } = this.props;
    const { isOpenLeftMenu, closeLeftMenu } = AppStore;
    const { user } = UserStore;
    return (
      <div className={cn('menu', { 'is--open': isOpenLeftMenu })}>
        <div className="menu__top">
          <div className="header header--third">
            <ul className="header__items">
              <li className="header__item header__item-link">
                <Link to="/" onClick={closeLeftMenu} className="logo">
                  <img src={logo} alt="BRONFY.com" className="logo__img" width="101" />
                </Link>
              </li>

              <li className="header__item header__item-link">
                <button type="button" onClick={closeLeftMenu} className="close" id="close-menu">
                  <span className="close__part" />
                  <span className="close__part" />
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="menu__middle">
          <ul className="menu__list">
            <li className="menu__list-item">
              <Link to="/about" onClick={closeLeftMenu} className="menu__list-link">
                <div className="menu__list-wrap">
                  <Icon className="menu__list-icon" width="17" height="15" name="menu-icon-house" />
                </div>

                <span className="menu__list-text">About Bronfy</span>
              </Link>
            </li>

            <li className="menu__list-item">
              <Link to="/forbusinesses" onClick={closeLeftMenu} className="menu__list-link">
                <div className="menu__list-wrap">
                  <Icon className="menu__list-icon" width="17" height="15" name="menu-icon-business" />
                </div>

                <span className="menu__list-text">For businesses</span>
              </Link>
            </li>

            <li className="menu__list-item">
              <span href="#" className="menu__list-link">
                <div className="menu__list-wrap">
                  <Icon className="menu__list-icon" width="18" height="18" name="menu-icon-lang" />
                </div>

                <span className="menu__list-text">
                  Language (
                  <strong>EN</strong>
                  )
                </span>
              </span>
            </li>

            {/* <li className="menu__list-item">
              <Link to="/help" onClick={closeLeftMenu} className="menu__list-link">
                <div className="menu__list-wrap">
                  <Icon className="menu__list-icon" width="19" height="19" name="menu-icon-help" />
                </div>

                <span className="menu__list-text">Help</span>
              </Link>
            </li> */}

            <li className="menu__list-item">
              <Link to="/contact" onClick={closeLeftMenu} className="menu__list-link">
                <div className="menu__list-wrap">
                  <Icon className="menu__list-icon" width="17" height="17" name="menu-icon-phone" />
                </div>

                <span className="menu__list-text">Contact Us</span>
              </Link>
            </li>
          </ul>
          <div className="socials-wrap socials-wrap--second">
            <p className="socials-wrap__text">Follow us:</p>
            <ul className="socials socials--second">
              <li className="socials__item">
                <a
                  href="https://www.facebook.com/Bronfycom-113502717113821"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials__link"
                >
                  <Icon className="socials__link-icon" width="10" height="21" name="fb-icon" />
                </a>
              </li>

              <li className="socials__item">
                <a
                  href="https://www.instagram.com/bronfy_com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials__link"
                >
                  <Icon className="socials__link-icon" width="18" height="18" name="inst-icon" />
                </a>
              </li>

              <li className="socials__item">
                <a
                  href="https://www.linkedin.com/company/bronfy-com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socials__link"
                >
                  <Icon className="socials__link-icon" width="22" height="22" name="lnk-icon" />
                </a>
              </li>
            </ul>
          </div>


        </div>

        {user && (
          <div className="menu__bottom">
            <p className="auth-row">
              <button type="button" className="auth-row__link" onClick={this.handleSignOut}>
                <Icon className="auth-row__icon" width="17" height="18" name="auth-icon" />
                <span className="auth-row__text">Sign Out</span>
              </button>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default inject('AppStore', 'UserStore')(observer(LeftMenu));
