import { observable, decorate, action } from 'mobx';
import { sendPost } from 'fetchApi';

class RestorePasswordStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  error = undefined;

  setPasswordStatus = undefined;

  setPasswordError = undefined;

  async send(email) {
    this.status = 'pending';
    this.error = undefined;
    try {
      await sendPost('signin/restore/email', { email });
      this.status = 'done';
    } catch (e) {
      this.error = e?.message;
      this.status = 'error';
    }
  }

  async setNewPassword(code, password) {
    this.setPasswordStatus = 'pending';
    this.setPasswordError = undefined;
    try {
      await sendPost('signin/restore/password', { code, password });
      this.setPasswordStatus = 'done';
    } catch (e) {
      this.setPasswordError = e?.message;
      this.setPasswordStatus = 'error';
    }
  }

  reset() {
    this.status = undefined;
    this.error = undefined;
    this.setPasswordStatus = undefined;
    this.setPasswordError = undefined;
  }
}

export default decorate(RestorePasswordStore, {
  status: observable,
  error: observable,
  setPasswordStatus: observable,
  setPasswordError: observable,
  send: action.bound,
  setNewPassword: action.bound,
  reset: action.bound,
});
