/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import {inject, observer} from 'mobx-react';
import cn from 'classnames';
import Header from 'components/Header';
import PlacesList from 'components/PlacesList';
import Categories from 'components/Categories';
import SubCategories from 'components/SubCategories';
import HorizontalList from 'components/HorizontalList';
import Icon from 'components/Icon';
import {MobileView, isMobile, isDesktop, BrowserView} from 'react-device-detect';
// import cardIconSvg from '../img/card-icon.svg';
import banner from '../img/dekstop_mainPage.svg';
import {Link} from "react-router-dom";

let _isShowSubCategories = true;

function sortCondition(a, b) {
  if (a === b) return 0;
  if (a === null) return 1;
  if (b === null) return -1;
  return a - b;
}

class MainPage extends React.PureComponent {
  searchRef = React.createRef();

  constructor(props) {
    super(props);
    const {match, location} = props;
    const params = new URLSearchParams(location.search);
    this.state = {
      isShowSubCategories: _isShowSubCategories,
      isShowAdvancedFilter: !!params.get('tags'),
      prevCategoryId: match?.params?.categoryId,
    };
  }

  componentDidMount() {
    const {AppStore, location} = this.props;
    const isSearchPage = location.pathname.slice(0, 7) === '/search';
    AppStore.menuOpenSectionName = isSearchPage ? 'search' : 'main';
  }

  static getDerivedStateFromProps(props, state) {
    const {AppStore, location} = props;
    if (location.pathname.slice(0, 7) === '/search') {
      AppStore.menuOpenSectionName = 'search';
    } else if (location.pathname.slice(0, 7) === '/') {
      AppStore.menuOpenSectionName = 'main';
      _isShowSubCategories = true;
    }
    if (props.match?.params?.categoryId !== state.prevCategoryId) {
      const params = new URLSearchParams(location.search);
      return {
        prevCategoryId: props.match?.params?.categoryId,
        isShowSubCategories: _isShowSubCategories,
        isShowAdvancedFilter: !!params.get('tags'),
      };
    }
    return null;
  }

  handleToggleShowFilter = () => {
    const {isShowSubCategories, isShowAdvancedFilter} = this.state;
    const {history, location, match} = this.props;
    _isShowSubCategories = !isShowSubCategories;
    if (_isShowSubCategories) {
      window.scrollTo(0, 0);
    } else {
      history.replace({...location, pathname: `/category/${match?.params?.categoryId}`, search: ''});
    }
    this.setState({
      isShowSubCategories: _isShowSubCategories,
      isShowAdvancedFilter: _isShowSubCategories ? isShowAdvancedFilter : false,
    });
  }

  handleShowAdvancedFilter = () => {
    this.setState({isShowAdvancedFilter: true});
  }

  handleClearSearch = (e) => {
    e.preventDefault();
    this.searchRef.current.value = '';
    this.handleSearch(e);
  }

  handleSearch = (e) => {
    e.preventDefault();
    const {history, location} = this.props;
    const params = new URLSearchParams(location.search);
    const tags = params.get('tags');
    history.replace({
      search:
        this.searchRef.current.value
          ? `?q=${this.searchRef.current.value}${tags ? `&tags=${tags}` : ''}`
          : `${tags ? `?tags=${tags}` : ''}`,
    });
  }

  handleTagSearch = (e) => {
    e.preventDefault();
    const {history, location} = this.props;
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('q');
    let tags = params.get('tags') ? params.get('tags').split(',') : [];

    const tag = e.currentTarget.innerText;
    if (tags.includes(tag)) tags = tags.filter((v) => v !== tag);
    else tags.push(tag);
    let search = '';
    if (searchQuery) search += `?q=${searchQuery}`;
    if (tags.length > 0) {
      search += searchQuery ? '&' : '?';
      search += `tags=${tags.join(',')}`;
    }

    history.replace({search});
  }

  handleAddFavorite = (id) => {
    const {UserStore, history} = this.props;
    if (!UserStore.user) {
      history.push('/signin');
    } else {
      UserStore.addFavorite(id);
    }
  }

  render() {
    let {isShowSubCategories} = this.state;
    const {isShowAdvancedFilter} = this.state;
    const {
      AppStore, CategoryStore, PlaceStore, UserStore, match, history, location,
    } = this.props;

    let searchQuery;
    let selectedTags = [];
    if (location?.search) {
      const params = new URLSearchParams(location.search);
      searchQuery = params.get('q');
      selectedTags = params.get('tags') ? params.get('tags').split(',') : [];
    }

    const {categories, categoriesArray} = CategoryStore;
    const {places, status} = PlaceStore;
    const selectedCategory = match?.params?.categoryId
      ? categories[parseInt(match.params.categoryId, 10)]
      : null;
    const subcategoryId = parseInt(match?.params?.subcategoryId, 10);
    let selectedSubCategory = null;
    if (subcategoryId) {
      selectedSubCategory = selectedCategory.subcategories.find((v) => v.id === subcategoryId);
    }
    isShowSubCategories = isShowSubCategories || !!selectedSubCategory;

    if (status === 'pending') return 'Loading...';
    if (status === 'error') return 'Loading error';

    const isMainPage = location.pathname.slice(0, 2) === '/';
    const isShowOnlyNew = location.pathname.slice(0, 4) === '/new';
    const isShowOnlyPromo = location.pathname.slice(0, 6) === '/promo';
    const isShowOnlyPopular = location.pathname.slice(0, 8) === '/popular';
    const isSearchPage = location.pathname.slice(0, 7) === '/search';

    let filteredPlaces = places.filter((({city}) => city === AppStore.city?.id));
    let popularTags;

    if (selectedCategory || isSearchPage) {
      if (selectedCategory) {
        filteredPlaces = filteredPlaces
          .filter((place) => place.categories.includes(selectedCategory.id)
            && (!selectedSubCategory || place.subcategories.includes(selectedSubCategory.id)))
          .map((place) => {
            let {tags} = place;
            tags = tags.filter((tag) => {
              if (selectedSubCategory) {
                return CategoryStore.tags[tag].subcategoryId === selectedSubCategory.id;
              }
              return !!selectedCategory.subcategories.find(({id}) => id === CategoryStore.tags[tag].subcategoryId);
            });
            return {
              ...place,
              tags,
            };
          });
      }

      const popularTagsObj = filteredPlaces.reduce((a, place) => {
        // eslint-disable-next-line no-param-reassign
        place.tags.forEach((tag) => {
          a[tag] = a[tag] ? a[tag] + 1 : 1;
        });
        return a;
      }, {});

      if (searchQuery || selectedTags.length > 0) {
        filteredPlaces = filteredPlaces.map((place) => ({
          ...place,
          findedName: searchQuery ? place.name.toLowerCase().includes(searchQuery.toLowerCase()) : true,
          findedWords:
            selectedTags
              .reduce(
                (a, name) => a + (place.tags.find((tag) => CategoryStore.tags[tag].name === name) ? 1 : 0),
                0,
              ),
        }))
          .filter(({findedWords, findedName}) => findedWords === selectedTags.length && findedName);
      }

      popularTags = Object
        .keys(popularTagsObj)
        .sort((a, b) => popularTagsObj[b] - popularTagsObj[a])
        .slice(0, 9)
        .map((tagId) => CategoryStore.tags[tagId].name)
        .sort();
      popularTags = [...popularTags, ...selectedTags.filter((tag) => !popularTags.includes(tag))];
    }

    const isShowMiniBlocks = !isSearchPage && !isShowOnlyNew && !isShowOnlyPromo && !isShowOnlyPopular;

    const newPlaces = (isShowMiniBlocks || isShowOnlyNew) && (filteredPlaces || places)
      .filter(({isNew}) => isNew)
      .sort((a, b) => sortCondition(a.newIndex, b.newIndex));

    const promoPlaces = (isShowMiniBlocks || isShowOnlyPromo) && (filteredPlaces || places)
      .filter(({isPromo}) => isPromo)
      .sort((a, b) => sortCondition(a.promoIndex, b.promoIndex));

    const popularPlaces = (isShowMiniBlocks || isShowOnlyPopular) && (filteredPlaces || places)
      .filter(({isPopular}) => isPopular)
      .sort((a, b) => sortCondition(a.popularIndex, b.popularIndex));

    let headerTitle = '';
    let placesTitle = '';
    if (isShowOnlyNew) {
      headerTitle = 'New';
      placesTitle = 'New on Bronfy';
      filteredPlaces = newPlaces;
    } else if (isShowOnlyPromo) {
      headerTitle = 'Campaign & Discounts';
      placesTitle = 'Campaign & Discounts';
      filteredPlaces = promoPlaces;
    } else if (isShowOnlyPopular) {
      headerTitle = 'Popular';
      placesTitle = 'Popular';
      filteredPlaces = popularPlaces;
    } else if (isSearchPage) {
      placesTitle = 'Search';
    }

    return (
      <>
        {isMobile && ((selectedCategory === null && isShowMiniBlocks) || isSearchPage)
          ? <Header/>
          : isMobile ? (
            <Header
              type="second"
              isFilterOpen={isShowSubCategories}
              title={headerTitle || selectedCategory.name}
              isShowFilterToggler={isShowMiniBlocks}
              onFilterToggle={this.handleToggleShowFilter}
              history={history}
            />

          ) : ''}

        <main className="layout layout--main" key={`${selectedCategory?.id}_${isShowMiniBlocks}`}>
          {isDesktop && isMainPage ?
            <div className='main_banner'>
              <div className="banner_wrap-add">
                <div className="banner_wrap">
                  <div className="banner_text">
                    <h1>
                      Best service on Bronfy
                    </h1>
                    <p>
                      Bronfy.com is a unique platform where you can find different services in one place. Enjoy and explore more with us.
                    </p>
                    <Link to={UserStore.user ? '/profile' : '/signup'}
                          onClick={() => {
                            AppStore.menuOpenSectionName = 'profile';
                          }}>
                      <button>
                        Get started
                      </button>
                    </Link>

                  </div>
                  <div className='banner_img'>
                    <img src={banner} alt="Banner"/>
                  </div>
                </div>
              </div>
            </div>
            : ''}
          {selectedCategory === null && isShowMiniBlocks
          && <Categories categories={categoriesArray}/>}

          {((selectedCategory !== null && isShowMiniBlocks) || isSearchPage)
          && (
            <>
              <MobileView className="container">
                <div className="fixed-form-search">
                  <form action="" className="form-search">
                    <label htmlFor="search-input">
                      <Icon className="form-search__icon" name="search-icon" width="14" height="15"/>
                      <input
                        id="search-input"
                        type="search"
                        defaultValue={searchQuery}
                        className="form__input"
                        placeholder="Search..."
                        ref={this.searchRef}
                        onChange={this.handleSearch}
                      />
                    </label>
                    {searchQuery && (
                      <label htmlFor="search-clear" className="form__label form-search__clear">
                        <input
                          type="button"
                          className="visually-hidden"
                          id="search-clear"
                          onClick={this.handleClearSearch}
                        />
                        ×
                      </label>
                    )}
                  </form>
                </div>
                <div className="fixed-form-search-space"/>

                {/*<li className="header__item header__item-btn">*/}
                {/*  {isShowMiniBlocks && (*/}
                {/*    <button*/}
                {/*      type="button"*/}
                {/*      className={cn(['btn', 'btn--second', {'filter--open': isShowSubCategories}])}*/}
                {/*      onClick={this.handleToggleShowFilter}*/}
                {/*    >*/}
                {/*      <Icon className="btn__icon" name="filter-icon" width="19" height="15"/>*/}
                {/*      <span className="btn__text">{isShowSubCategories ? 'Hide' : 'Filter'}</span>*/}
                {/*    </button>*/}
                {/*  )}*/}
                {/*</li>*/}

                {isShowSubCategories && !isSearchPage
                && (
                  <SubCategories
                    value={selectedSubCategory}
                    category={selectedCategory}
                  />
                )}

                {isShowSubCategories && popularTags.length > 0 && !isSearchPage && !isShowAdvancedFilter
                && (
                  <button
                    className="btn"
                    type="button"
                    onClick={this.handleShowAdvancedFilter}
                  >
                    Advanced Filter
                  </button>
                )}

                {((isShowSubCategories && isShowAdvancedFilter) || isSearchPage) && (popularTags?.length > 0) && (
                  <div className={cn(
                    'form-filter__row',
                    'form-filter__row--tags',
                    {'form-filter__row--search_page': isSearchPage},
                  )}
                  >
                    <ul className="form-filter__items">
                      <p className="form-filter__title">Popular Tags:</p>
                      {popularTags.map((tag) => (
                        <li className="form-filter__item" key={tag}>
                          <button
                            type="button"
                            className={cn('form-filter__link', {'is--active': selectedTags.includes(tag)})}
                            onClick={this.handleTagSearch}
                          >
                            {tag}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <br/>
                  </div>
                )}

              </MobileView>
              <BrowserView className="container">
                {isShowSubCategories && !isSearchPage
                && (
                  <SubCategories
                    value={selectedSubCategory}
                    category={selectedCategory}
                  />
                )}
                <div className="search-web-container">
                  <form action="" className="form-search-web">
                    <label htmlFor="search-input">
                      <Icon className="form-search__icon" name="search-icon" width="14" height="15"/>
                      <input
                        id="search-input"
                        type="search"
                        defaultValue={searchQuery}
                        className="form__input change_search"
                        placeholder="Search..."
                        ref={this.searchRef}
                        onChange={this.handleSearch}
                      />
                    </label>
                    {searchQuery && (
                      <label htmlFor="search-clear" className="form__label form-search__clear">
                        <input
                          type="button"
                          className="visually-hidden"
                          id="search-clear"
                          onClick={this.handleClearSearch}
                        />
                        ×
                      </label>
                    )}
                  </form>
                  {((isShowSubCategories) || isSearchPage) && (popularTags?.length > 0) && (
                    <div className={cn(
                      'form-filter__row',
                      'form-filter__row--tags',
                      'form-filter__row--search_page'
                    )}
                    >
                      <ul className="form-filter__items">
                        <li className="form-filter__item form-filter__title-web">Popular Tags:</li>
                        {popularTags.map((tag) => (
                          <li className="form-filter__item" key={tag}>
                            <button
                              type="button"
                              className={cn('form-filter__link-web', {'is--active': selectedTags.includes(tag)})}
                              onClick={this.handleTagSearch}
                            >
                              {tag}
                            </button>
                          </li>
                        ))}
                      </ul>
                      <br/>
                    </div>
                  )}
                </div>
              </BrowserView>
            </>
          )}

          {isShowMiniBlocks && promoPlaces.length > 0
          && (
            <HorizontalList
              type="promo"
              title="Campaign & Discounts"
              places={promoPlaces.slice(0, 10)}
              categories={categories}
              to="/promo"
            />
          )}
          {isShowMiniBlocks && newPlaces.length > 0
          && (
            <HorizontalList
              type="new"
              title="New on Bronfy"
              places={newPlaces.slice(0, 10)}
              categories={categories}
              to="/new"
            />
          )}
          {isShowMiniBlocks && popularPlaces.length > 0
          && (
            <HorizontalList
              type="popular"
              title="Popular"
              places={popularPlaces.slice(0, 10)}
              categories={categories}
              to="/popular"
            />
          )}


          {!isMainPage && (filteredPlaces && filteredPlaces.length > 0
            ? (
              <PlacesList
                isGoBack={(isShowOnlyNew || isShowOnlyPromo || isShowOnlyPopular)}
                history={history}
                places={filteredPlaces}
                favorites={UserStore.user?.favorites}
                onAddFavorite={this.handleAddFavorite}
                onRemoveFavorite={UserStore.removeFavorite}
                categories={categories}
                isPromo={isShowOnlyPromo}
                title={placesTitle || selectedCategory.name}
                bigCards={(selectedCategory !== null && isShowMiniBlocks) || isSearchPage}
              />
            )
            : (<div style={{textAlign: 'center', paddingTop: '40px', fontSize: '18px'}}>No results found</div>))}
        </main>
      </>
    );
  }
}

export default inject('AppStore', 'CategoryStore', 'PlaceStore', 'UserStore')(observer(MainPage));
