import { decorate, observable, action } from 'mobx';

class PlaceStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  error = undefined;

  places = undefined;

  async load() {
    this.status = 'pending';
    this.error = undefined;
    try {
      const res = await fetch('/api/place');
      const { data } = await res.json();
      this.places = data.map((place) => ({
        ...place,
        options: place.options,
        coords: place.coords?.split(',').map((v) => parseFloat(v)),
      }));
      this.status = 'done';
    } catch (e) {
      console.error(e);
      this.error = e.message;
      this.status = 'error';
    }
  }
}

export default decorate(PlaceStore, {
  load: action.bound,
  status: observable,
  error: observable,
  places: observable,
});
