import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import Icon from 'components/Icon/Icon';
import {isMobile} from 'react-device-detect';
import Slider from "react-slick";

export default class HorizontalList extends PureComponent {
  state = {};

  handleClickFavorite = (id) => (e) => {
    e.preventDefault();
    const {onAddFavorite} = this.props;
    onAddFavorite(id);
  }

  handleClickRemoveFavorite = (id) => (e) => {
    e.preventDefault();
    const {onRemoveFavorite} = this.props;
    onRemoveFavorite(id);
  }

  render() {
    const {
      title, places, to, type, categories, favorites = []
    } = this.props;

    let cardClassName = '';
    if (type === 'new') {
      cardClassName = 'card--second';
    }
    if (type === 'popular') {
      cardClassName = 'card--third';
    }
    if (isMobile) {
      return (
        <section className="layout__sect">
          <div className="blocks">
            <div className="blocks__top">
              <h3 className="blocks__top-title">{title}</h3>
              <Link
                to={(location) => ({
                  ...location,
                  pathname: location.pathname.indexOf('/category/') === 0
                    ? location.pathname.replace('/category', to) : to,
                })}
                className="blocks__top-link"
              >
                View All
              </Link>
            </div>

            <div className="blocks__body">
              <ul className="cards-list">
                {places.map((place) => (
                  <li className="cards-list__item" key={place.id}>
                    <Link to={`/place/${place.id}`} className={`card ${cardClassName}`}>
                      <div className="card__img">
                        {type === 'promo' && place.promoPoster
                          ? <img src={`${place.promoPoster}/700`} alt="" className="object-fit is--cover"/>
                          : place.poster && <img src={`${place.poster}/700`} alt="" className="object-fit is--cover"/>}
                        {type === 'new' && place.isNew && <div className="card__label-new">New</div>}
                      </div>

                      {(type === 'new' || type === 'promo') && (
                        <div className="card__info">
                          <h2 className="card__info-title">{place.name}</h2>
                          <div className="card__info-wrap">
                            <div className="card__info-icon card__info-icon--grey">
                              {categories && place.categories && place.categories.map((catId, i) => categories[catId]
                                && [
                                  <Icon key={catId} name={`category-${categories[catId].icon}-icon`}/>,
                                  (i % 2 ? <br key={`br_${catId}`}/> : null),
                                ])}
                            </div>
                            <p
                              className="card__info-text card__info-tags"
                            >
                              {type === 'promo' ? place.promoText : place.description}
                            </p>
                          </div>
                        </div>
                      )}

                      {type === 'popular' && (
                        <div className="card__info">
                          <div className="card__info-wrap">
                            <div className="card__info-icon card__info-icon--grey">
                              {categories && place.categories && place.categories.map((catId) => categories[catId]
                                && <Icon key={catId} name={`category-${categories[catId].icon}-icon`}/>)}
                            </div>
                            <h2 className="card__info-title">{place.name}</h2>
                          </div>
                        </div>
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      );
    }
    return (
      <div className={type === "profile"? "places_cont_profile" :"places_cont"}>
        <div className={type === "profile"? "horlist_add_wrap profile_list_pad" :"horlist_add_wrap"}>
          <div className="horLis_wrap">
            <div className="wrap-header-horList">
              <h1 className="categories_horList_h1">
                {title}
              </h1>
              <Link
                to={(location) => ({
                  ...location,
                  pathname: location.pathname.indexOf('/category/') === 0
                    ? location.pathname.replace('/category', to) : to,
                })}
                className="blocks__top-link-web"
              >
                View All
              </Link>
            </div>
            <div className='main_horList__hr'/>
            {type === 'profile' && (
              <Slider
                dots={false}
                arrows={true}
                infinite={false}
                speed={500}
                slidesToShow={places.length > 2 ? 3 : places.length}
                slidesToScroll={2}
                className="slider-horListProfile"
                responsive={[
                  {
                    breakpoint: 1220,
                    settings: {
                      slidesToShow: (places.length > 1 ? 2 : places.length),
                      slidesToScroll: (places.length > 1 ? 2 : places.length),
                      classname: "slider-horListProfile",
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 820,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      classname: "slider-horListProfile",
                      arrows: true
                    }
                  }
                ]}
              >
                {places.map((place) => (
                  <li className="horList_card_li" key={place.id}>
                    <Link to={`/place/${place.id}`} className='card-horList-web_profile'>

                      <div className="card__img_placesList">
                        {place.poster && <img src={`${place.poster}/700`} alt="" className="object-fit is--cover"/>}

                      </div>
                      <li className={"favorite_pos_placesList-big header__item"}>
                        {favorites.includes(place.id)
                          ? (
                            <button type="button" className="btn-favorite" onClick={this.handleClickRemoveFavorite(place.id)}>
                              <Icon className="btn-favorite__icon" name="favorite-full-icon" width="21" height="19"/>
                            </button>
                          )
                          : (
                            <button type="button" className="btn-favorite" onClick={this.handleClickFavorite(place.id)}>
                              <Icon className="btn-favorite__icon" name="favorite-icon" width="21" height="19"/>
                            </button>
                          )}
                      </li>

                      <div className={"card__info-web-big"}>
                        <h2 className={"card__info-title-places-big"}>{place.name}</h2>

                        <div className={"card__info-wrap_placesList-big"}>

                          <p className="card__info-text card__info-tags">{place.description}</p>
                        </div>
                      </div>


                    </Link>
                  </li>
                ))}
              </Slider>
            )}
            {(type === 'new' || type === 'promo') && (
              <Slider
                dots={false}
                arrows={true}
                infinite={false}
                speed={500}
                slidesToShow={places.length > 4 ? 5 : places.length}
                slidesToScroll={2}
                className="slider-horList1"
                responsive={[
                  {
                    breakpoint: 1500,
                    settings: {
                      slidesToShow: (places.length > 3 ? 4 : places.length),
                      slidesToScroll: 2,
                      classname: "slider-horList1",
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 1300,
                    settings: {
                      slidesToShow: (places.length > 2 ? 3 : places.length),
                      slidesToScroll: 2,
                      classname: "slider-horList1",
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 1050,
                    settings: {
                      slidesToShow: (places.length > 1 ? 2 : places.length),
                      slidesToScroll: 2,
                      classname: "slider-horList1",
                      arrows: true
                    }
                  }
                ]}
              >
                {places.map((place) => (
                  // <li className="cards-list__item" key={place.id}>
                  <li className="horList_card_li" key={place.id}>
                    <Link to={`/place/${place.id}`} className={`card-horList-web`}>
                      <div className="card__img-horList1">
                        {type === 'promo' && place.promoPoster
                          ? <img src={`${place.promoPoster}/700`} alt="" className="object-fit is--cover"/>
                          : place.poster && <img src={`${place.poster}/700`} alt="" className="object-fit is--cover"/>}
                        {type === 'new' && place.isNew && <div className="card__label-new">New</div>}
                      </div>

                      {(type === 'new' || type === 'promo') && (
                        <div className="card__info">
                          <h2 className="card__info-title-horList1">{place.name}</h2>
                          <div className="card__info-wrap_horList1">
                            <div className="card__info-icon card__info-icon--grey">
                              {categories && place.categories && place.categories.map((catId, i) => categories[catId]
                                && [
                                  <Icon key={catId} name={`category-${categories[catId].icon}-icon`}/>,
                                  (i % 2 ? <br key={`br_${catId}`}/> : null),
                                ])}
                            </div>
                            <p
                              className="card__info-text_horList1 card__info-tags"
                            >
                              {type === 'promo' ? place.promoText : place.description}
                            </p>
                          </div>
                        </div>
                      )}

                    </Link>
                  </li>
                ))}
              </Slider>
            )}

            {type === 'popular' && (
              <Slider
                dots={false}
                arrows={true}
                infinite={false}
                speed={500}
                slidesToShow={places.length > 5 ? 6 : places.length}
                slidesToScroll={2}
                className="slider-horList2"
                responsive={[
                  {
                    breakpoint: 1500,
                    settings: {
                      slidesToShow: (places.length > 4 ? 5 : places.length),
                      slidesToScroll: 2,
                      classname: "slider-horList2",
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 1300,
                    settings: {
                      slidesToShow: (places.length > 3 ? 4 : places.length),
                      slidesToScroll: 2,
                      classname: "slider-horList2",
                      arrows: true
                    }
                  },
                  {
                    breakpoint: 1050,
                    settings: {
                      slidesToShow: (places.length > 2 ? 3 : places.length),
                      slidesToScroll: 2,
                      classname: "slider-horList2",
                      arrows: true
                    }
                  }
                ]}
              >
                {places.map((place) => (
                  <li className="horList_card_li" key={place.id}>
                    <Link to={`/place/${place.id}`} className={`card-horList-web2`}>
                      <div className="card__img-horList2">
                        {type === 'promo' && place.promoPoster
                          ? <img src={`${place.promoPoster}/700`} alt="" className="object-fit is--cover"/>
                          : place.poster && <img src={`${place.poster}/700`} alt="" className="object-fit is--cover"/>}
                        {type === 'new' && place.isNew && <div className="card__label-new">New</div>}
                      </div>

                      {type === 'popular' && (
                        <div className="card__info-horList2">
                          <div className="card__info-wrap_horList2">
                            <div className="card__info-icon_horList2 card__info-icon--grey">
                              {categories && place.categories && place.categories.map((catId) => categories[catId]
                                && <Icon key={catId} name={`category-${categories[catId].icon}-icon`}/>)}
                            </div>
                            <h2 className="card__info-title-horList2">{place.name}</h2>
                          </div>
                        </div>
                      )}
                    </Link>
                  </li>
                ))}
              </Slider>
            )}

          </div>
        </div>
      </div>
    )
  }
}
