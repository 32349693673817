/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Header from 'components/Header';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import Icon from 'components/Icon/Icon';
import ChangePasswordPage from "./ChangePasswordPage";
import {isMobile} from "react-device-detect";
import HorizontalList from "../components/HorizontalList";

class ProfilePage extends React.PureComponent {
  state = {
    editRow: null,
    changePass: false,
    save: {},
    hasEdit: false
  }

  componentDidMount() {
    const {AppStore} = this.props;
    AppStore.menuOpenSectionName = 'profile';
  }

  handleClickEdit = (field) => () => {
    const {editRow} = this.state;
    if (!editRow) this.setState({editRow: field});
  }

  handleSave = (field, e) => {
    e.preventDefault();
    const {UserStore} = this.props;
    UserStore.saveProfileInfo({[field]: e.currentTarget.value});
    setTimeout(() => this.setState({editRow: null}), 100);
  }

  handleDesktopSave = (e) => {
    e.preventDefault()
    const {UserStore} = this.props;
    UserStore.saveProfileInfo(this.state.save);
    setTimeout(() => this.setState({save: {}, hasEdit: false}), 100);
  }

  handleInput = (field, e) => {
    e.preventDefault()
    const { UserStore } = this.props;
    const save = this.state.save;

    if (save.hasOwnProperty(field)) {
      if (e.currentTarget.value === UserStore.user[field]) {
        delete save[field];
      } else {
        save[field] = e.currentTarget.value;
      }
    } else {
      if (e.currentTarget.value !== UserStore.user[field]) {
        save[field] = e.currentTarget.value;
      }
    }

    this.setState({save})
    const keys = Object.keys(save);
    this.setState({hasEdit: !!keys.length})
  }

  handleOpenModal = () => {
    document.body.classList.add('hide--scroll');
    this.setState({changePass: true})
  }

  handleCloseModal = () => {
    document.body.classList.remove('hide--scroll');
    this.setState({changePass: false})
  }

  render() {
    const {editRow} = this.state;
    const {PlaceStore, UserStore} = this.props;
    const {user} = UserStore;
    const {places, status} = PlaceStore;

    if (status === 'pending') return 'Loading...';
    if (status === 'error') return 'Loading error';

    const filteredPlacesFavorites = user?.favorites && places.filter(({id}) => user.favorites.includes(id));

    const placesById = {};
    if (user?.history) places.forEach((place) => {
      placesById[place.id] = place;
    });
    const filteredPlacesHistory = user?.history.map((id) => placesById[id]);

    if (isMobile) {
      return (
        <>
          <Header/>
          <main className="layout layout--main">
            <section className="layout__sect layout__sect--main">
              <div className="container">
                <h1 className="layout__sect-title">My Profile</h1>
                <ul className="layout__sect-list">
                  <li className="layout__sect-item">
                    <div className="layout__sect-contains" onClick={this.handleClickEdit('name')}>
                      <p className="layout__sect-text"><strong>Name</strong></p>
                      {editRow !== 'name' && <p className="layout__sect-text">{UserStore.user.name}</p>}
                      {editRow === 'name' && (
                        <p className="form-search form-profile">
                          <input
                            defaultValue={UserStore.user.name}
                            className="form__input"
                            onKeyPress={(e) => (e.key === 'Enter' ? this.handleSave('name', e) : undefined)}
                            onBlur={(e) => this.handleSave('name', e)}
                            autoFocus
                          />
                        </p>
                      )}
                    </div>
                  </li>

                  <li className="layout__sect-item">
                    <div className="layout__sect-contains" onClick={this.handleClickEdit('phone')}>
                      <p className="layout__sect-text"><strong>Phone</strong></p>
                      {editRow !== 'phone' && <p className="layout__sect-text">{UserStore.user.phone}</p>}
                      {editRow === 'phone' && (
                        <p className="form-search form-profile">
                          <input
                            defaultValue={UserStore.user.phone}
                            className="form__input"
                            onKeyPress={(e) => (e.key === 'Enter' ? this.handleSave('phone', e) : undefined)}
                            onBlur={(e) => this.handleSave('phone', e)}
                            autoFocus
                          />
                        </p>
                      )}
                    </div>
                  </li>

                  <li className="layout__sect-item">
                    <div className="layout__sect-contains" onClick={this.handleClickEdit('email')}>
                      <p className="layout__sect-text"><strong>Email</strong></p>
                      {editRow !== 'email' && (
                        <p className="layout__sect-text">
                          {UserStore.user.email}
                          {UserStore.user.isNeedConfirmed && (
                            <>
                              <br/>
                              <span style={{color: 'red'}}>Need confirm email!</span>
                            </>
                          )}
                        </p>
                      )}
                      {editRow === 'email' && (
                        <p className="form-search form-profile">
                          <input
                            defaultValue={UserStore.user.email}
                            className="form__input"
                            onKeyPress={(e) => (e.key === 'Enter' ? this.handleSave('email', e) : undefined)}
                            onBlur={(e) => this.handleSave('email', e)}
                            autoFocus
                          />
                        </p>
                      )}
                    </div>
                  </li>

                  <li className="layout__sect-item">
                    <div className="layout__sect-contains">
                      <p className="layout__sect-text">
                        <Link to="/history" className="layout__sect-link"><strong>History</strong></Link>
                      </p>
                    </div>
                  </li>

                  <li className="layout__sect-item">
                    <div className="layout__sect-contains">
                      <p className="layout__sect-text">
                        <Link to="/favorite" className="layout__sect-link"><strong>My favorites</strong></Link>
                      </p>
                    </div>
                  </li>

                  <li className="layout__sect-item">
                    <div className="layout__sect-contains">
                      <p className="layout__sect-text">
                        <Link to="/profile/password" className="layout__sect-link"><strong>Change
                          password</strong></Link>
                      </p>
                    </div>
                  </li>

                </ul>

                <p className="auth-row">
                  <button type="button" className="auth-row__link" onClick={UserStore.signOut}>
                    <Icon name="auth-icon" className="auth-row__icon" width="17" height="18"/>
                    <span className="auth-row__text">Sign Out</span>
                  </button>
                </p>

                <div style={{
                  position: 'absolute', bottom: '70px', right: '10px', opacity: '0.3', fontSize: '12px',
                }}
                >
                  App version:
                  {' '}
                  {window.appVersion}
                </div>
              </div>
            </section>
          </main>
        </>
      );
    }
    return (
      <>
        {this.state.changePass ? <ChangePasswordPage isPopUp={true} closeModal={this.handleCloseModal}/> : ''}
        <main className="layout layout--main">
          <div className="profile-web-container">
            <div className="wrap-web-profile">
              <div className="profile-form-wrap">
                <div className="profile-web-header">
                  <div>
                    <h1 className="profile-h1-web">My Profile</h1>
                  </div>
                  <button className="profile-button-logout" onClick={UserStore.signOut}>Logout</button>
                  <button
                    onClick={(e) => this.handleDesktopSave(e)}
                    disabled={!this.state.hasEdit}
                    className={this.state.hasEdit? "profile-button-update" : "profile-button-update disabled-button"}>
                    Update
                  </button>
                </div>
                <hr className='profile__hr'/>
                <div className="profile-inputs">
                  <div className="profile-inputs-column">
                    <div className="profile-input">
                      <p>Name</p>
                      <input
                        defaultValue={UserStore.user.name}
                        value={!this.state.save['name'] && !this.state.hasEdit? UserStore.user.name: this.value}
                        onInput={(e) => this.handleInput('name', e)}
                        type="text"/>
                    </div>
                    <div className="profile-input add_pad">
                      <p>Phone</p>
                      <input
                        defaultValue={UserStore.user.phone}
                        value={!this.state.save['phone'] && !this.state.hasEdit? UserStore.user.phone: this.value}
                        onInput={(e) => this.handleInput('phone', e)}
                        type="text"/>
                    </div>
                  </div>
                  <div className="profile-inputs-column">
                    <div className="profile-input">
                      <p>Email</p>
                      <input
                        defaultValue={UserStore.user.email}
                        value={!this.state.save['email'] && !this.state.hasEdit? UserStore.user.email: this.value}
                        onInput={(e) => this.handleInput('email', e)}
                        type="email"/>
                    </div>
                    <div className="profile-input-password">
                      <div className="profile-input">
                        <p>Password</p>
                        <input type="password" disabled={true} value={"password"}/>
                      </div>
                      <button className="profile-change-pass-button" onClick={() => this.handleOpenModal()}>Change
                        password
                      </button>
                    </div>
                  </div>
                </div>
                {filteredPlacesFavorites && filteredPlacesFavorites.length > 0
                  ? (
                    <HorizontalList
                      type="profile"
                      title="My Favorites"
                      places={filteredPlacesFavorites.slice(0, 10)}
                      favorites={UserStore.user?.favorites}
                      onAddFavorite={UserStore.addFavorite}
                      onRemoveFavorite={UserStore.removeFavorite}
                      to="/favorite"
                    />) : ""}

                {filteredPlacesHistory && filteredPlacesHistory.length > 0
                  ? (
                    <HorizontalList
                      type="profile"
                      title="My History"
                      places={filteredPlacesHistory.slice(0, 10)}
                      favorites={UserStore.user?.favorites}
                      onAddFavorite={UserStore.addFavorite}
                      onRemoveFavorite={UserStore.removeFavorite}
                      to="/history"
                    />) : ""}
              </div>
            </div>
          </div>
          {/*<section className="layout__sect layout__sect--main">*/}
          {/*  <div className="container">*/}
          {/*    <h1 className="layout__sect-title">My Profile</h1>*/}
          {/*    <ul className="layout__sect-list">*/}
          {/*      <li className="layout__sect-item">*/}
          {/*        <div className="layout__sect-contains" onClick={this.handleClickEdit('name')}>*/}
          {/*          <p className="layout__sect-text"><strong>Name</strong></p>*/}
          {/*          {editRow !== 'name' && <p className="layout__sect-text">{UserStore.user.name}</p>}*/}
          {/*          {editRow === 'name' && (*/}
          {/*            <p className="form-search form-profile">*/}
          {/*              <input*/}
          {/*                defaultValue={UserStore.user.name}*/}
          {/*                className="form__input"*/}
          {/*                onKeyPress={(e) => (e.key === 'Enter' ? this.handleSave('name', e) : undefined)}*/}
          {/*                onBlur={(e) => this.handleSave('name', e)}*/}
          {/*                autoFocus*/}
          {/*              />*/}
          {/*            </p>*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*      </li>*/}

          {/*      <li className="layout__sect-item">*/}
          {/*        <div className="layout__sect-contains" onClick={this.handleClickEdit('phone')}>*/}
          {/*          <p className="layout__sect-text"><strong>Phone</strong></p>*/}
          {/*          {editRow !== 'phone' && <p className="layout__sect-text">{UserStore.user.phone}</p>}*/}
          {/*          {editRow === 'phone' && (*/}
          {/*            <p className="form-search form-profile">*/}
          {/*              <input*/}
          {/*                defaultValue={UserStore.user.phone}*/}
          {/*                className="form__input"*/}
          {/*                onKeyPress={(e) => (e.key === 'Enter' ? this.handleSave('phone', e) : undefined)}*/}
          {/*                onBlur={(e) => this.handleSave('phone', e)}*/}
          {/*                autoFocus*/}
          {/*              />*/}
          {/*            </p>*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*      </li>*/}

          {/*      <li className="layout__sect-item">*/}
          {/*        <div className="layout__sect-contains" onClick={this.handleClickEdit('email')}>*/}
          {/*          <p className="layout__sect-text"><strong>Email</strong></p>*/}
          {/*          {editRow !== 'email' && (*/}
          {/*            <p className="layout__sect-text">*/}
          {/*              {UserStore.user.email}*/}
          {/*              {UserStore.user.isNeedConfirmed && (*/}
          {/*                <>*/}
          {/*                  <br/>*/}
          {/*                  <span style={{color: 'red'}}>Need confirm email!</span>*/}
          {/*                </>*/}
          {/*              )}*/}
          {/*            </p>*/}
          {/*          )}*/}
          {/*          {editRow === 'email' && (*/}
          {/*            <p className="form-search form-profile">*/}
          {/*              <input*/}
          {/*                defaultValue={UserStore.user.email}*/}
          {/*                className="form__input"*/}
          {/*                onKeyPress={(e) => (e.key === 'Enter' ? this.handleSave('email', e) : undefined)}*/}
          {/*                onBlur={(e) => this.handleSave('email', e)}*/}
          {/*                autoFocus*/}
          {/*              />*/}
          {/*            </p>*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*      </li>*/}

          {/*      <li className="layout__sect-item">*/}
          {/*        <div className="layout__sect-contains">*/}
          {/*          <p className="layout__sect-text">*/}
          {/*            <Link to="/history" className="layout__sect-link"><strong>History</strong></Link>*/}
          {/*          </p>*/}
          {/*        </div>*/}
          {/*      </li>*/}

          {/*      <li className="layout__sect-item">*/}
          {/*        <div className="layout__sect-contains">*/}
          {/*          <p className="layout__sect-text">*/}
          {/*            <Link to="/favorite" className="layout__sect-link"><strong>My favorites</strong></Link>*/}
          {/*          </p>*/}
          {/*        </div>*/}
          {/*      </li>*/}

          {/*      <li className="layout__sect-item">*/}
          {/*        <div className="layout__sect-contains">*/}
          {/*          <p className="layout__sect-text">*/}
          {/*            <button onClick={() => this.handleOpenModal()} className="layout__sect-link"><strong>Change*/}
          {/*              password</strong></button>*/}
          {/*          </p>*/}
          {/*        </div>*/}
          {/*      </li>*/}

          {/*    </ul>*/}

          {/*    <p className="auth-row">*/}
          {/*      <button type="button" className="auth-row__link" onClick={UserStore.signOut}>*/}
          {/*        <Icon name="auth-icon" className="auth-row__icon" width="17" height="18"/>*/}
          {/*        <span className="auth-row__text">Sign Out</span>*/}
          {/*      </button>*/}
          {/*    </p>*/}

          {/*    <div style={{*/}
          {/*      position: 'absolute', bottom: '70px', right: '10px', opacity: '0.3', fontSize: '12px',*/}
          {/*    }}*/}
          {/*    >*/}
          {/*      App version:*/}
          {/*      {' '}*/}
          {/*      {window.appVersion}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}
        </main>
      </>
    );
  }
}

export default inject(
  ({UserStore, AppStore, PlaceStore}) => ({UserStore, AppStore, PlaceStore}),
)(observer(ProfilePage));
