/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PureComponent} from 'react';
import Header from 'components/Header';
import {observer, inject} from 'mobx-react';
import {isMobile, isDesktop} from 'react-device-detect';

class MapPage extends PureComponent {
  state = {
    selectedCountry: null,
    selectedCity: null,
  };

  mapRef = React.createRef();

  componentDidMount() {
    this.drawMap();
    this.zoomToUser();
  }

  componentDidUpdate() {
    this.drawMap();
    const {LocationStore} = this.props;
    if (this.lastPosition !== LocationStore.position || (LocationStore.position && !this.userMarker)) {
      this.lastPosition = LocationStore.position;
      this.zoomToUser();
    }
  }

  zoomToUser = () => {
    const {LocationStore} = this.props;
    if (this.lastPosition && this.map) {
      const {latitude, longitude} = LocationStore.position.coords;
      if (!this.userMarker) {
        this.userMarker = new window.google.maps.Marker({
          position: {lat: latitude, lng: longitude},
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 4,
            fillColor: 'dodgerblue',
            fillOpacity: 0.8,
            strokeColor: 'dodgerblue',
            strokeWeight: 2,
          },
          map: this.map,
        });
        this.map.setCenter({lat: latitude, lng: longitude});
      } else {
        this.userMarker.setPosition({lat: latitude, lng: longitude});
      }
    }
  }

  selectCountry = (e) => {
    e.preventDefault()
    const { CountryStore } = this.props;
    const { countries } = CountryStore;
    const realSelect = countries.filter(country => country.id === +e.currentTarget.value)
    this.setState({selectedCountry: realSelect[0], selectedCity: null})
  }

  selectCity = (e) => {
    e.preventDefault()
    const { AppStore } = this.props;
    const { country } = AppStore;

    // if(+e.currentTarget.value === city.id) {
    //   this.setState({selectedCity: null})
    //   return
    // }

    if (this.state.selectedCountry !== null) {
      const realSelect = this.state.selectedCountry.cities.filter(c => c.id === +e.currentTarget.value);
      this.setState({selectedCity: realSelect[0]})
    } else {
      const realSelect = country.cities.filter(c => c.id === +e.currentTarget.value)
      this.setState({selectedCity: realSelect[0]})
    }

  }

  updateCity = () => {
    const {AppStore} = this.props;
    AppStore.city = this.state.selectedCity;
    this.setState({selectedCity: null, selectedCountry: null});
    this.map = null;
    this.drawMap()
    this.zoomToUser()
  }

  drawMap() {
    if (this.map) return;
    const {PlaceStore, AppStore, history} = this.props;
    const {status, places} = PlaceStore;
    if (status === 'pending') return;
    if (status === 'error') return;
    const filteredPlaces = places.filter(({coords}) => coords);
    if (filteredPlaces.length === 0) return;
    const center = AppStore.city.coords ? {lat: AppStore.city.coords[0], lng: AppStore.city.coords[1]} : undefined;
    const infowindow = new window.google.maps.InfoWindow({
      content: ' ',
    });
    this.map = new window.google.maps.Map(this.mapRef.current, {
      center,
      zoom: 13,
    });
    const markers = filteredPlaces.map((place) => {
      const [lat, lng] = place.coords;
      const marker = new window.google.maps.Marker({
        map: this.map,
        position: {lat, lng},
      });
      const markerContent = document.createElement('a');
      markerContent.href = `/place/${place.id}`;
      markerContent.class = 'card card--third';
      markerContent.onclick = (e) => {
        e.preventDefault();
        history.push(`/place/${place.id}`);
      };
      markerContent.innerHTML = `<div class="card__img">
      <img src=${place.poster} alt="" class="object-fit is--cover"/>
      ${place.isNew ? '<div class="card__label-new">New</div>' : ''}
    </div>
    <div class="card__info">
      <div class="card__info-wrap">
        <h2 class="card__info-title">${place.name}</h2>
      </div>
    </div>`;
      const {map} = this;
      window.google.maps.event.addListener(marker, 'click', function () {
        infowindow.setContent(markerContent);
        infowindow.open(map, this);
      });
      return marker;
    });
    if (!center) this.map.setCenter(markers[0].getPosition());
  }

  render() {
    const {PlaceStore, LocationStore, history, CountryStore, AppStore} = this.props;
    const {
      hasGeoAccess, toggleGeoAccess,
    } = LocationStore;
    const {selectedCountry, selectedCity} = this.state;
    const {country, city} = AppStore;
    const {status} = PlaceStore;
    const {countries} = CountryStore;
    if (status === 'pending') return 'Loading...';
    if (status === 'error') return 'Loading error';

    // eslint-disable-next-line no-unused-vars
    const hasCoords = !!LocationStore.position?.coords;

    return (
      <>
        {isMobile ? <Header
          isShowFilterToggler={false}
          history={history}
        /> : ''}

        <main className="layout layout--main">
          <section className="layout__sect--main">
            {isMobile && (hasGeoAccess || (
              <div className="container" style={{height: 78}}>
                <div className="switch">
                  <p className="switch__text"><strong>Use geolocation</strong></p>

                  <div className="switch__wrap">
                    <input
                      type="checkbox"
                      className="visually-hidden"
                      id="switch"
                      checked={hasGeoAccess}
                      onChange={toggleGeoAccess}
                    />
                    <label className="switch__part" htmlFor="switch"/>
                  </div>
                </div>
              </div>
            ))}
            {isDesktop && (
              <div className="container" style={{height: 78}}>
                <div className="modalOnMap">
                  <p className="switch_text_desktop"><strong>Use geolocation</strong></p>
                  <div className="switch__wrap">
                    <input
                      type="checkbox"
                      className="visually-hidden"
                      id="switch"
                      checked={hasGeoAccess}
                      onChange={toggleGeoAccess}
                    />
                    <label className="switch__part" htmlFor="switch"/>
                  </div>

                  <select id={"select_country"} className="selectModal" onChange={(e) => this.selectCountry(e)}>
                    {
                      countries.map((c, index) =>
                        (
                          <>
                            <option
                              onSelect={() => this.onSelectCountry(c)}
                              selected={selectedCountry && selectedCountry.id === c.id ? true
                                : !selectedCountry && country.id === c.id}
                              key={index} value={c.id}>{c.name}
                            </option>
                          </>
                        )
                      )
                    }
                  </select>
                  <div className="position_arrow_select">
                    <span className="arrow_select"/>
                  </div>

                  <select id={"select_city"} className="selectModal" onChange={(e) => this.selectCity(e)}>

                    <option selected={selectedCountry} hidden={true}>Choose city</option>

                    {selectedCountry?.cities ? selectedCountry.cities.map((c, index) =>
                      (
                        <option
                          selected={!!(selectedCity && selectedCity.id === c.id)}
                          key={index} value={c.id}>{c.name}
                        </option>
                      )
                    ) : country.cities.map((c, index) =>
                      (
                        <option
                          selected={selectedCity && selectedCity.id === c.id? true
                            :!selectedCity && city.id === c.id}
                          key={index} value={c.id}>{c.name}
                        </option>
                      )
                    )}

                  </select>
                  <div className="position_arrow_select">
                    <span className="arrow_select"/>
                  </div>

                  <button
                    onClick={() => this.updateCity()}
                    disabled={!selectedCity}
                    className={selectedCity? "map_button" : "map_button disabled-button"}>
                    Search
                  </button>

                </div>
              </div>
            )}
            <div ref={this.mapRef} style={{height: isDesktop? 'calc(100% - 78px)': hasGeoAccess ? '100%' : 'calc(100% - 78px)'}}/>
          </section>
        </main>
      </>
    );
  }
}

export default inject('AppStore', 'PlaceStore', 'LocationStore', 'CountryStore')(observer(MapPage));
