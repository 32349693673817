import { decorate, observable, action } from 'mobx';
import { sendPost } from 'fetchApi';

const convertedVapidKey = urlBase64ToUint8Array('BNQxwgan3F9zqnHLKyUin3O5Sbv01vZJYLtsFLuMAIqQ2BDcIyDmh0MDIbYFiQFZ81XKA4fVsEwcaSQ3K7amm24');

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

class PushNotificationStore {
  constructor(stores) {
    this.stores = stores;
  }

  status = undefined;

  permission = undefined;

  // eslint-disable-next-line class-methods-use-this
  sendSubscription(subscription) {
    sendPost('push/subscription', subscription);
  }

  // eslint-disable-next-line class-methods-use-this
  subscribeUser() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (!registration.pushManager) {
          // console.log('Push manager unavailable.');
          return;
        }

        registration.pushManager.getSubscription().then((existedSubscription) => {
          if (existedSubscription === null) {
            // console.log('No subscription detected, make a request.');
            registration.pushManager.subscribe({
              applicationServerKey: convertedVapidKey,
              userVisibleOnly: true,
            }).then((newSubscription) => {
              // console.log('New subscription added.');
              this.sendSubscription(newSubscription);
            }).catch((e) => {
              if (Notification.permission !== 'granted') {
                console.log('Permission was not granted.');
              } else {
                console.error('An error ocurred during the subscription process.', e);
              }
            });
          } else {
            // console.log('Existed subscription detected.');
            this.sendSubscription(existedSubscription);
          }
        });
      })
        .catch((e) => {
          console.error('An error ocurred during Service Worker registration.', e);
        });
    }
  }

  checktNotificationPermissionState() {
    if (navigator.permissions) {
      return navigator.permissions.query({ name: 'notifications' })
        .then((result) => {
          this.permission = result.state;
        });
    }
    this.permission = Notification.permission;

    return new Promise((resolve) => {
      resolve(Notification.permission);
    });
  }

  async requestPermission() {
    await this.checktNotificationPermissionState();
    if (this.permission === 'granted') return;
    Notification.requestPermission((permission) => {
      this.permission = permission;
      if (this.permission === 'granted') {
        this.subscribeUser();
      }
    });
  }
}

export default decorate(PushNotificationStore, {
  subscribeUser: action.bound,
  checktNotificationPermissionState: action.bound,
  requestPermission: action.bound,
  permission: observable,
  status: observable,
});
