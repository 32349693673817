/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cn from 'classnames';
import Icon from 'components/Icon';
import {Link} from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import logo from '../../img/logo.svg';
import sprite from "../../img/sprite.svg";
import {isMobile} from 'react-device-detect';

const Header = ({
                  AppStore, UserStore, type, title, isShowFilterToggler, isFilterOpen, onFilterToggle, history, onBack, onClose,
                  openLeftMenuOnBack,
                }) => {
  if (isMobile) {
    if (type === 'logo') {
      return (
        <>
          {AppStore.alertText && <p className="alert aller--logo"><span>{AppStore.alertText}</span></p>}
          <header className="header">
            <Link to="/" className="logo">
              <img src={logo} alt="BRONFY.com" className="logo__img" width="233"/>
            </Link>
          </header>
        </>
      );
    }

    if (type === 'second') {
      return (
        <>
          <header className="header header--second">
            <div className="container">
              <ul className="header__items">
                <li className="header__item header__item-link">
                  <button
                    type="button"
                    onClick={() => {
                      if (openLeftMenuOnBack) {
                        AppStore.openLeftMenu();
                        setTimeout(() => history.goBack(), 150);
                      } else {
                        history.goBack();
                      }
                    }}
                    className="btn-link btn-link--second"
                  >
                    <Icon className="btn-link__icon" name="back-icon" width="21" height="12"/>
                    <span className="btn-link__text">{title}</span>
                  </button>
                </li>

                <li className="header__item header__item-btn">
                  {isShowFilterToggler && (
                    <button
                      type="button"
                      className={cn(['btn', 'btn--second', {'filter--open': isFilterOpen}])}
                      onClick={onFilterToggle}
                    >
                      <Icon className="btn__icon" name="filter-icon" width="19" height="15"/>
                      <span className="btn__text">{isFilterOpen ? 'Hide' : 'Filter'}</span>
                    </button>
                  )}
                </li>

                <li className="header__item header__item-link">
                  <button type="button" onClick={AppStore.showLocationModal} className="btn-link">
                    <Icon name="location-icon" className="btn-link__icon" width="13" height="16"/>
                    <span className="btn-link__text">{AppStore.city?.name}</span>
                  </button>
                </li>
              </ul>
            </div>
          </header>
          {AppStore.alertText && <p className="alert"><span>{AppStore.alertText}</span></p>}
        </>
      );
    }

    if (type === 'popup') {
      return (
        <header className="header header--third">
          <div className="container">
            <ul className="header__items">

              {onBack && (
                <li className="header__item header__item-link">
                  <button
                    type="button"
                    onClick={onBack}
                    className="btn-link btn-link--second"
                  >
                    <Icon className="btn-link__icon" name="back-icon" width="21" height="12"/>
                    <span className="btn-link__text">{title}</span>
                  </button>
                </li>
              )}

              {!onBack && (
                <li className="header__item header__item-link">
                  <Link to="/" onClick={onClose} className="logo">
                    <img src={logo} alt="BRONFY.com" className="logo__img" width="101"/>
                  </Link>
                </li>
              )}

              <li className="header__item header__item-link">
                <button type="button" onClick={onClose} className="close">
                  <span className="close__part"/>
                  <span className="close__part"/>
                </button>
              </li>
            </ul>
          </div>
        </header>
      );
    }

    return (
      <>
        <header className="header header--main">
          <div className="container">
            <ul className="header__items">
              <li className="header__item">
                <div className="sandwich" onClick={AppStore.openLeftMenu}>
                  <span className="sandwich__part"/>
                  <span className="sandwich__part"/>
                  <span className="sandwich__part"/>
                </div>
              </li>

              <li className="header__item header__item-logo">
                <Link to="/" className="logo">
                  <img
                    src={logo}
                    alt="BRONFY.com"
                    className="logo__img"
                    width="130"
                  />
                </Link>
              </li>

              <li className="header__item header__item-link">
                <button type="button" onClick={AppStore.showLocationModal} className="btn-link">
                  <Icon name="location-icon" className="btn-link__icon" width="13" height="16"/>
                  <span className="btn-link__text">{AppStore.city?.name}</span>
                </button>
              </li>
            </ul>
          </div>
        </header>
        {AppStore.alertText && <p className="alert"><span>{AppStore.alertText}</span></p>}
      </>
    );
  }
  return (
    <>
      <header className="header-web header--main-web">
        <div className="container header_cont">
          <div className="header-wrap">
            <ul className="header__items-web">

              <li className="header__item-web header__item-logo">
                <Link to="/" className="logo">
                  <img
                    src={logo}
                    alt="BRONFY.com"
                    className="logo__img"
                    width="150"
                    height="32"
                  />
                </Link>
              </li>

              <li className="header__item-web">
                <Link to="/forbusinesses" className="menu__list-link-web header_text">
                  <span className="menu__list-text-web">For Businesses</span>
                </Link>

                <Link to="/about" className="menu__list-link-web header_text">
                  <span className="menu__list-text-web">About Bronfy</span>
                </Link>

                <Link
                  to="/map"
                  onClick={() => {
                    AppStore.menuOpenSectionName = 'map';
                  }}
                  className="menu__list-link-web header_text"
                >
                  <span className="menu__list-text-web">Geolocation</span>
                </Link>

                <Link to="/contact" className="menu__list-link-web header_text">
                  <span className="menu__list-text-web">Contact Us</span>
                </Link>

                <Link className="menu__list-link-web icons-header"
                      to="/search"
                      onClick={() => {
                        AppStore.menuOpenSectionName = 'search';
                      }}
                >
                  <svg className="nav__list-icon" width="15" height="15">
                    <use xlinkHref={`${sprite}#search-icon`}/>
                  </svg>
                </Link>

                <Link className="menu__list-link-web"
                      to={UserStore.user ? '/profile' : '/signin'}
                      onClick={() => {
                        AppStore.menuOpenSectionName = 'profile';
                      }}
                >
                  <svg className="nav__list-icon" width="15" height="15">
                    <use xlinkHref={`${sprite}#profile-icon`}/>
                  </svg>
                </Link>
                <Link className="menu__list-link-web"
                      to="/favorite"
                      onClick={() => {
                        AppStore.menuOpenSectionName = 'favorite';
                      }}
                >
                  <svg className="nav__list-icon" width="15" height="15">
                    <use xlinkHref={`${sprite}#heart-icon`}/>
                  </svg>
                </Link>

                <Link className="menu__list-link-web icons-header modal_header"
                      to="#"
                >
                  <button type="button" className="btn-link modal_header">
                    <Icon name="location-icon" className="btn-link__icon" width="13" height="16"/>
                    <span className="btn-link__text">{AppStore.city?.name}</span>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {AppStore.alertText && <p className="alert"><span>{AppStore.alertText}</span></p>}
    </>
  );
};

export default inject('AppStore', 'UserStore')(observer(Header));
