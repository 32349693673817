/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Icon from 'components/Icon';
import SocialSigIn from 'components/SocialSigIn';
import { inject, observer } from 'mobx-react';
import {isMobile, isDesktop} from 'react-device-detect';

const errorsText = {
  EmptyEmail: 'Email field is required',
  InvalidEmail: 'Invalid email address or password',
  EmailAlreadyRegistered: 'This email is already registered',
  EmptyPassword: 'Password is required',
  InvalidPassword: 'Invalid email address or password',
};

class SignInPage extends React.Component {
  state = {
    isPasswordHidden: true,
  };

  emailRef = React.createRef();

  passwordRef = React.createRef();

  searchParams = new URLSearchParams(window.location.search); // TODO refact it!!!!!

  isRedirectFromGoogle = this.searchParams.get('auth') === 'google' && !!this.searchParams.get('code');

  isRedirectFromFacebook = this.searchParams.get('auth') === 'facebook' && !!this.searchParams.get('code');

  googleCode = undefined;

  componentDidMount() {
    const { SignInFormStore, AppStore } = this.props;
    AppStore.menuOpenSectionName = 'profile';
    if (this.isRedirectFromGoogle) {
      SignInFormStore.sendGoogleAuthCode(this.searchParams.get('code'));
    }
    if (this.isRedirectFromFacebook) {
      SignInFormStore.sendFacebookAuthCode(this.searchParams.get('code'));
    }
  }

  componentWillUnmount() {
    const { SignInFormStore } = this.props;
    SignInFormStore.reset();
  }

  handleShowPassword = () => {
    this.setState((state) => ({ isPasswordHidden: !state.isPasswordHidden }));
  }

  handleSignIn = (e) => {
    e.preventDefault();
    const { SignInFormStore } = this.props;
    const email = this.emailRef.current.value;
    const password = this.passwordRef.current.value;
    SignInFormStore.send(email, password);
  }

  render() {
    const { isPasswordHidden } = this.state;
    const { SignInFormStore } = this.props;
    if ((this.isRedirectFromGoogle || this.isRedirectFromFacebook)
      && (!SignInFormStore.status || SignInFormStore.status === 'prendind')) {
      return (
        <main className="layout">
          <Header type="logo" />
          Loading...
        </main>
      );
    }
    const { error } = SignInFormStore;
    return (

      <main className="layout">
        {isMobile? <Header type="logo" />: ''}
        <div className={isDesktop? 'sign-in_web' : ''}>
          <div className={isDesktop? 'wrap-web' : ''}>
            {isDesktop? <h1 className='login__h1'>Login</h1> : ''}
            {isDesktop? <hr className='login__hr'/>: ''}

        <form action="/" className={isMobile? "form" : "form-web"} noValidate>
          <ul className={isDesktop? "form__items " : "form__items"}>

            <li className="form__item">
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                className={cn(['form__input',
                  { 'is--error': error === 'EmptyEmail' || error === 'InvalidEmail' || error === 'UserNotFound' },
                ])}
                id="user-email"
                ref={this.emailRef}
              />
              <label htmlFor="user-email" className="form__input-icon">
                <Icon name="email-icon" width="22" height="17" />
              </label>
            </li>

            <li className="form__item">
              <Icon
                name="eye-icon"
                className={cn([
                  'sprite-icon', 'sprite-icon--second', 'sprite-icon--eye', { 'is--active': !isPasswordHidden },
                ])}
                width="18"
                height="18"
                onClick={this.handleShowPassword}
              />
              <input
                name="password"
                type={isPasswordHidden ? 'password' : 'text'}
                placeholder="Password"
                className={cn(['form__input',
                  { 'is--error': error === 'EmptyPassword' || error === 'InvalidPassword' },
                ])}
                id="user-password"
                ref={this.passwordRef}
              />
              <label htmlFor="user-password" className="form__input-icon">
                <Icon name="lock-icon" width="22" height="17" />
              </label>
            </li>

            <li className="form__item checkbox">
              <ul className="form__elements">
                <li className="form__element form__element-checkbox" />
                <li className="form__element">
                  <Link to="/restore" className="form__link">Forgot password?</Link>
                </li>
              </ul>
            </li>

            <li className="form__item form__item-submit">
              <button type="submit" className="btn-submit" onClick={this.handleSignIn}>Sign in</button>
            </li>
          </ul>
          {SignInFormStore.error
            && <div className="form__error"><p>{errorsText[SignInFormStore.error] || SignInFormStore.error}</p></div>}
        </form>

        <div className="layout__link">
          <p>
            Not a member?
            {' '}
            <Link to="/signup" className="layout__link-part">Sign Up</Link>
          </p>
        </div>

        <span className="layout__divider">Or</span>

        <SocialSigIn />
        <div style={{
          position: 'absolute', bottom: '70px', right: '10px', opacity: '0.3', fontSize: '12px',
        }}
        >
          App version:
          {' '}
          {window.appVersion}
        </div>
        </div>
        </div>
      </main>
    );
  }
}

export default inject(
  ({ SignInFormStore, AppStore }) => ({ SignInFormStore, AppStore }),
)(observer(SignInPage));
