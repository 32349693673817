/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { observer, inject } from 'mobx-react';
import cn from 'classnames';
import Header from 'components/Header';
import {isDesktop, isMobile} from "react-device-detect";

const errorsText = {};
class ContactPage extends PureComponent {
  countryRef = React.createRef();

  nameRef = React.createRef();

  phoneRef = React.createRef();

  emailRef = React.createRef();

  messageRef = React.createRef();

  componentWillUnmount() {
    const { ContactFormStore } = this.props;
    ContactFormStore.reset();
  }

  handleSubmit = (e) => {
    const { ContactFormStore } = this.props;
    e.preventDefault();
    ContactFormStore.send({
      country: this.countryRef.current.value,
      name: this.nameRef.current.value,
      phone: this.phoneRef.current.value,
      email: this.emailRef.current.value,
      message: this.messageRef.current.value,
    });
  }

  render() {
    const { history, CountryStore, ContactFormStore } = this.props;
    const { countries } = CountryStore;
    const { error, status } = ContactFormStore;
    return (
      <>
        {isMobile? <Header
          isShowFilterToggler={false}
          history={history}
          type={history.action === 'PUSH' ? 'second' : undefined}
          title="Back"
          openLeftMenuOnBack
        /> : ''}

        <div className={isDesktop? 'sign-in_web' : ''}>
          <div className={isDesktop? 'wrap-web-contact' : ''}>

        <main className={isDesktop? "layout layout--main-web" : "layout layout--main"}>
          <section className="layout__sect layout__sect--main">
            <div className="container">
              <h1 className="layout__sect-title">Contact Us</h1>

              {status === 'done' && (
                <div style={{ paddingTop: '100px', textAlign: 'center'}}>
                  Your message is successfully delivered.
                  <br />
                  We will contact you very shortly.
                </div>
              )}
              {status !== 'done' && (
                <>
                  <p className="layout__sect-subtitle">Fill your information</p>

                  <form action="" className="form form--second">
                    <ul className="form__items">
                      <li className="form__item">
                        <label className="form__item-label is--required">Select your country:</label>

                        <div className="select-wrapper">
                          <select
                            ref={this.countryRef}
                            className={cn(['form__input', 'form__input-select', "form-input-back",
                              { 'is--error': error === 'EmptyCountry' },
                            ])}
                          >
                            {countries.map(({ name }) => <option key={name} value={name}>{name}</option>)}
                          </select>
                        </div>
                      </li>

                      <li className="form__item">
                        <label className="form__item-label is--required" htmlFor="user-name">Your name:</label>
                        <input
                          type="text"
                          placeholder=""
                          id="user-name"
                          ref={this.nameRef}
                          className={cn(['form__input',
                            { 'is--error': error === 'EmptyName' },
                          ])}
                        />
                      </li>

                      <li className="form__item">
                        <label className="form__item-label is--required" htmlFor="user-phone">Your phone:</label>
                        <input
                          type="tel"
                          placeholder=""
                          className={cn(['form__input',
                            { 'is--error': error === 'EmptyPhone' },
                          ])}
                          id="user-phone"
                          ref={this.phoneRef}
                        />
                      </li>

                      <li className="form__item">
                        <label className="form__item-label is--required" htmlFor="user-email">Email:</label>
                        <input
                          type="email"
                          placeholder=""
                          className={cn(['form__input',
                            { 'is--error': error === 'EmptyEmail' || error === 'InvalidEmail' },
                          ])}
                          id="user-email"
                          ref={this.emailRef}
                        />
                      </li>

                      <li className="form__item">
                        <label className="form__item-label is--required" htmlFor="business-email">Message:</label>
                        <textarea
                          placeholder=""
                          className={cn(['form__input',
                            { 'is--error': error === 'EmptyMessage' },
                          ])}
                          id="business-email"
                          ref={this.messageRef}
                        />
                      </li>

                      <li className="form__item form__item-submit">
                        <button type="submit" className="btn-submit" onClick={this.handleSubmit}>Submit</button>
                      </li>
                      <li className="form__item form__item-mandanatory">
                        <span className="is--required" />
                        {' '}
                        Mandanatory fields
                      </li>
                    </ul>
                    {error
                      && <div className="form__error"><p>{errorsText[error] || error}</p></div>}
                  </form>
                </>
              )}
            </div>
          </section>
        </main>
          </div>
        </div>
      </>
    );
  }
}

export default inject('CountryStore', 'ContactFormStore')(observer(ContactPage));
