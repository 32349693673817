/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {PureComponent} from 'react';
import Header from 'components/Header';
import {observer, inject} from 'mobx-react';
import {isMobile, isDesktop} from 'react-device-detect';

class PlaceMapPage extends PureComponent {
  mapRef = React.createRef();

  componentDidMount() {
    this.drawMap();
  }

  componentDidUpdate() {
    this.drawMap();
    const {LocationStore} = this.props;
    if (this.lastPosition !== LocationStore.position || (LocationStore.position && !this.userMarker)) {
      this.lastPosition = LocationStore.position;
      this.drawUserMarker();
    }
  }

  drawUserMarker = () => {
    const {PlaceStore, LocationStore, match} = this.props;
    const {status, places} = PlaceStore;
    if (status === 'pending') return;
    if (status === 'error') return;
    if (this.lastPosition && this.map) {
      const placeId = parseInt(match.params.placeId, 10);
      const place = places.find((v) => v.id === placeId);
      if (!place || !place.coords) return;
      const {latitude, longitude} = LocationStore.position.coords;
      if (this.userMarker) {
        this.userMarker.setPosition({lat: latitude, lng: longitude});
      } else {
        this.userMarker = new window.google.maps.Marker({
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 4,
            fillColor: 'dodgerblue',
            fillOpacity: 0.8,
            strokeColor: 'dodgerblue',
            strokeWeight: 2,
          },
          map: this.map,
          position: {lat: latitude, lng: longitude},
        });

        const directionsService = new window.google.maps.DirectionsService();
        const directionsDisplay = new window.google.maps.DirectionsRenderer({
          map: this.map,
        });

        const [lat, lng] = place.coords;
        directionsService.route({
          origin: {lat: latitude, lng: longitude},
          destination: {lat, lng},
          travelMode: window.google.maps.TravelMode.DRIVING,
        }, (response, responseStatus) => {
          if (responseStatus === window.google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
          } else {
            window.alert(`Directions request failed due to ${responseStatus}`);
          }
        });
      }
    }
  }

  drawMap() {
    if (this.map) return;
    const {PlaceStore, LocationStore, match} = this.props;
    const {status, places} = PlaceStore;
    if (status === 'pending') return;
    if (status === 'error') return;
    const placeId = parseInt(match.params.placeId, 10);
    const place = places.find((v) => v.id === placeId);
    if (!place || !place.coords) return;
    const [lat, lng] = place.coords;
    this.map = new window.google.maps.Map(this.mapRef.current, {
      center: {lat, lng},
      zoom: 14,
    });

    this.placeMarker = new window.google.maps.Marker({
      map: this.map,
      position: {lat, lng},
    });
    if (LocationStore.position) {
      this.lastPosition = LocationStore.position;
      this.drawUserMarker();
    }
    this.map.setCenter(this.placeMarker.getPosition());
  }

  render() {
    const {
      PlaceStore, history, match, LocationStore,
    } = this.props;

    const {
      hasGeoAccess, toggleGeoAccess,
    } = LocationStore;

    const {status, places} = PlaceStore;
    if (status === 'pending') return 'Loading...';
    if (status === 'error') return 'Loading error';
    const placeId = parseInt(match.params.placeId, 10);
    const place = places.find((v) => v.id === placeId);

    // eslint-disable-next-line no-unused-vars
    const hasCoords = !!LocationStore.position?.coords;

    if (!place) return 'Place  not found';
    return (
      <>
        {isMobile ? <Header
          type="second"
          title={place.name}
          isShowFilterToggler={false}
          onFilterToggle={this.handleToggleShowFilter}
          history={history}
        /> : ''}

        <main className="layout layout--main">
          {!hasGeoAccess && isMobile && (
            <div className="container">
              <div className="switch">
                <p className="switch__text"><strong>Use geolocation</strong></p>

                <div className="switch__wrap">
                  <input
                    type="checkbox"
                    className="visually-hidden"
                    id="switch"
                    checked={hasGeoAccess}
                    onChange={toggleGeoAccess}
                  />
                  <label className="switch__part" htmlFor="switch"/>
                </div>
              </div>
            </div>
          )}

          {isDesktop && (
            <div className="container">
              <div className="switch-web">
                <div className="wrap_map_back" onClick={() => history.goBack()}>
                  <div className="map_back_div">
                    <span className="map_back_arrow"/>
                  </div>
                  <div className="map_back_div2">
                    <span className="map_back_span">Go Back</span>
                  </div>
                </div>
                <div className="switch-web-div">
                  <p className="switch__text"><strong>Use geolocation</strong></p>

                  <div className="switch__wrap">
                    <input
                      type="checkbox"
                      className="visually-hidden"
                      id="switch"
                      checked={hasGeoAccess}
                      onChange={toggleGeoAccess}
                    />
                    <label className="switch__part" htmlFor="switch"/>
                  </div>
                </div>

              </div>
            </div>
          )}
          <section className="layout__sect--main">
            <div className="container" ref={this.mapRef}
                 style={{height: isDesktop ? 'calc(100% - 78px)' : hasGeoAccess ? '100%' : 'calc(100% - 78px)'}}/>
          </section>
        </main>
      </>
    );
  }
}

export default inject('AppStore', 'LocationStore', 'PlaceStore')(observer(PlaceMapPage));
