/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import Header from 'components/Header';
import { observer, inject } from 'mobx-react';
import about1 from '../img/about_phone.svg';
import {isMobile, MobileView, BrowserView} from "react-device-detect";

class AboutPage extends PureComponent {
  render() {
    const { history } = this.props;

    return (
      <>
        {isMobile? <Header
          isShowFilterToggler={false}
          history={history}
          type={history.action === 'PUSH' ? 'second' : undefined}
          title="Back"
          openLeftMenuOnBack
        /> : ''}

        <MobileView>
          <main className="layout layout--main">
            <section className="layout__sect layout__sect--main">
              <div className="container">
                <h1 className="layout__sect-title">About Bronfy</h1>
                <br />
                Welcome to Bronfy.com!
                <br />
                <br />
                Bronfy.com is marketplace platform founded and launched in 2020.
                <br />
                <br />
                Our aim is to offer unique marketplace where users can easily and quickly find different kind of services and book them for FREE.
                <br />
                <br />
                We are dedicated to improve our services further and offer the very best solutions to our users.
                <br />
                <br />
              </div>
            </section>
          </main>
        </MobileView>

        <BrowserView>
          <main className="layout layout--main">
            <section className="layout__sect layout__sect--main">
              <div className="container">
                <div className="about__top-block">
                  <div className="about__wrap-top">
                    <img src={about1} alt="about_phone_img" width={300}/>
                    <div className={"about_top_text"}>
                      <h1>Welcome to Bronfy.com!</h1>
                      <h3>History</h3>
                      <p>Bronfy.com is marketplace platform founded and launched in 2020.</p>
                      <br/>
                      <p>Our aim is to offer unique marketplace where users can easily and quickly find different kind of services and book them for FREE. We are dedicated to improve our services further and offer the very best solutions to our users.</p>
                    </div>
                  </div>
                </div>
                <div className="about__middle-block">
                  <div className="about__wrap">
                  <div className="about__middle-div">
                    <h1 className="about__middle-h1">255</h1>
                    <h3 className="about__middle-h3">Happy Clients</h3>
                  </div>
                  <div className="about__middle-div">
                    <h1 className="about__middle-h1">1787</h1>
                    <h3 className="about__middle-h3">Services</h3>
                  </div>
                  <div className="about__middle-div">
                    <h1 className="about__middle-h1">35</h1>
                    <h3 className="about__middle-h3">Countries</h3>
                  </div>
                </div>
                </div>
                <div className="about__bottom-block">
                  <div className="about__wrap-bottom">
                    <div>
                      <h3>Mission</h3>
                      <p>Our target is to create a great Product from the customers’/users’ perspective, and it is nothing about us.
                        In our vision, any product developed needs to be kept simple. As Albert Einstein said: “Everything should be made as simple as possible,
                        but not simpler”.
                        Thus, continuous interaction with customers through closely monitoring user experience and getting feedback can help us to achieve this goal.
                      </p>
                    </div>
                    <div>
                      <h3>Team</h3>
                      <p>Our experienced team is fully dedicated to meet established goals and perform at high level of quality.
                        We are continuously increasing number of our team members and if you are curious,
                        energetic and ready to be a part of innovation, just send us message.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </section>
          </main>
        </BrowserView>
      </>
    );
  }
}

export default inject('AppStore')(observer(AboutPage));
