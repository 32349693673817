import React from 'react';
import sprite from '../../img/sprite.svg';

const Icon = ({ name, ...otherProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg className="sprite-icon" {...otherProps}>
    <use xlinkHref={`${sprite}#${name}`} />
  </svg>
);

export default Icon;
